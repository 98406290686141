import { Badge } from "@mantine/core";
import {
  MatchStatus as MatchStatusModel,
  MatchStatuses,
} from "../../api/models/match";

interface MatchStatusProps {
  status: MatchStatusModel;
}

function MatchStatus({ status }: MatchStatusProps) {
  const statusType = status.status;

  let color: string = "yellow";
  if ([MatchStatuses.IN_PLAY, MatchStatuses.PAUSED].includes(statusType)) {
    color = "indigo";
  } else if (
    [MatchStatuses.AWARDED, MatchStatuses.FINISHED].includes(statusType)
  ) {
    color = "teal";
  } else if (
    [MatchStatuses.CANCELED, MatchStatuses.SUSPENDED].includes(statusType)
  ) {
    color = "red";
  }

  return (
    <div className="match-status">
      <Badge variant="light" color={color}>
        {status.humazine()}
      </Badge>
    </div>
  );
}

export default MatchStatus;
export { type MatchStatusProps };
