import {
  Group,
  Paper,
  RingProgress,
  Space,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconMedal } from "@tabler/icons-react";

interface RankingPositionProps {
  width?: string;
  size?: number;
  thickness?: number;
  position: number;
  nbPlayers: number;
}

function RankingPosition({
  width,
  size,
  thickness,
  position,
  nbPlayers,
}: RankingPositionProps) {
  let percent: number;
  // This is a special that may never happen but it's better to handle it
  if (nbPlayers === 1) {
    percent = 100;
  } else {
    percent = (1 - (position - 1) / (nbPlayers - 1)) * 100;
  }

  let ringColor: string = position === 1 ? "green" : "blue";
  // Same as for percent, handle the case where there is a single player
  let textColor: string;
  if (nbPlayers === 1 || position === 1) {
    textColor = "green";
  } else if (position === nbPlayers) {
    textColor = "red";
  } else {
    textColor = "blue";
  }

  let nbPlayerText = nbPlayers === 1 ? "player" : "players";

  return (
    <div className="ranking-position" style={{ width: width }}>
      <Paper shadow="xs" radius="md" p="xs">
        <Group position="apart">
          <Text color="dimmed" size="sm" weight={550}>
            Position
          </Text>
          <ThemeIcon variant="light" color="gray" size="md" radius="md">
            <IconMedal color="black" />
          </ThemeIcon>
        </Group>
        <Space h="1rem" />
        <Group>
          <RingProgress
            size={size}
            thickness={thickness}
            sections={[{ value: percent, color: ringColor }]}
            label={
              <Text color={textColor} weight={600} align="center" size="1.5rem">
                {position}
              </Text>
            }
          />
          <div>
            <Text weight={700} size="xl">
              {nbPlayers} {nbPlayerText}
            </Text>
          </div>
        </Group>
      </Paper>
    </div>
  );
}

export default RankingPosition;
export { type RankingPositionProps };
