import {
  Center,
  Group,
  Loader,
  Pagination,
  Paper,
  Space,
  Table,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

import { Ranking } from "../../api/models/games/ranking";
import { UserProfile } from "../../api/models/user";
import { useUserProfiles } from "../../hooks/api";
import AccountService from "../../services/account";
import { APIService } from "../../services/api";
import { IconCalendarStats } from "@tabler/icons-react";

interface RankingRowProps {
  userProfile: UserProfile;
  ranking: Ranking;
}

function RankingRow({ userProfile, ranking }: RankingRowProps) {
  return (
    <tr key={userProfile.uid} className="ranking-table__row">
      <td style={{ textAlign: "center" }}>
        <Text>{userProfile.username}</Text>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text>{ranking.score}</Text>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text>{ranking.position}</Text>
      </td>
    </tr>
  );
}

function RankingHeader() {
  return (
    <thead className="ranking-table__header">
      <tr>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            User
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Score
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Position
          </Text>
        </th>
      </tr>
    </thead>
  );
}

interface RankingTableProps {
  accountService: AccountService;
  apiService: APIService;
  userRanking: Ranking;
  opponentRankings: Ranking[];
  pageTotal: number;
  onPageChange(pageNumber: number): void;
}

function RankingTable({
  accountService,
  apiService,
  userRanking,
  opponentRankings,
  pageTotal,
  onPageChange,
}: RankingTableProps) {
  const uids = opponentRankings.map((or) => or.uid);
  const userProfiles = useUserProfiles(uids, accountService, apiService);

  const rows: Array<any> = [];
  // Create rows only when all user profiles have been retrieved
  if (userProfiles.status === "success") {
    opponentRankings.forEach((opponentRanking) => {
      const userProfile = userProfiles.data
        .filter((up) => up.uid === opponentRanking.uid)
        .at(0);
      if (userProfile === undefined) {
        return;
      }
      rows.push(
        <RankingRow userProfile={userProfile} ranking={opponentRanking} />
      );
    });
  }

  return (
    <div className="ranking-table">
      <Paper shadow="xs" radius="md" p="xs">
        <div style={{ height: "100%" }}>
          <Group position="apart">
            <Title order={4}>Score overview</Title>
            <ThemeIcon variant="light" color="gray" size="md" radius="md">
              <IconCalendarStats color="black" />
            </ThemeIcon>
          </Group>
        </div>
        {rows.length === 0 ? (
          <Center>
            <Loader variant="dots" />
          </Center>
        ) : (
          <>
            <Space h="1rem" />
            <Table>
              <RankingHeader />
              <tbody>{rows}</tbody>
            </Table>
          </>
        )}
        <Space h="0.5rem" />
        <Center>
          <Pagination total={pageTotal} onChange={onPageChange} />
        </Center>
      </Paper>
    </div>
  );
}

export default RankingTable;
export { type RankingTableProps };
