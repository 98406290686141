class UserFormAttrs {
  static readonly UID: string = "uid";
  static readonly NAME: string = "name";
  static readonly SURNAME: string = "surname";
  static readonly BIRTH_DATE: string = "birthDate";
  static readonly EMAIL: string = "email";
  static readonly PHOTO_URL: string = "photoUrl";
  static readonly USERNAME: string = "username";
}

class UserAttrs {
  static readonly ID: string = "id";
  static readonly UID: string = "uid";
  static readonly NAME: string = "name";
  static readonly SURNAME: string = "surname";
  static readonly BIRTH_DATE: string = "birthDate";
  static readonly EMAIL: string = "email";
  static readonly PHOTO_URL: string = "photoUrl";
  static readonly USERNAME: string = "username";
  static readonly ROLE: string = "role";
  static readonly BANNED: string = "banned";
}

class UserProfileAttrs {
  static readonly UID: string = "uid";
  static readonly USERNAME = "username";
  static readonly photoUrl = "photoUrl";
}

export default UserAttrs;
export { UserFormAttrs, UserProfileAttrs };
