import {
  Group,
  Header as MantineHeader,
  Text,
  createStyles,
  rem,
} from "@mantine/core";
import UserMenu from "../Core/UserMenu";
import { Account } from "../../services/account";

interface HeaderProps {
  onCreateAccount(event?: React.MouseEvent): void;
  onLogIn(event?: React.MouseEvent): void;
  onSettings(event?: React.MouseEvent): void;
  onLogOut(): void;
  account: Account | null;
  [key: string]: any;
}

const useStyles = createStyles((theme) => ({
  userMenu: {
    [theme.fn.smallerThan("sm")]: {
      width: "auto",
      marginLeft: "auto",
    },
  },
}));

function Header({
  onCreateAccount,
  onLogIn,
  onSettings,
  onLogOut,
  account,
}: HeaderProps) {
  const { classes } = useStyles();

  return (
    <div className="header">
      <MantineHeader height={rem(56)} sx={{ height: "100%" }}>
        <Group sx={{ height: "100%" }} px={20} position="apart">
          <Text fz="lg">SoccerPro</Text>
          <UserMenu
            onCreateAccount={onCreateAccount}
            onLogIn={onLogIn}
            onSettings={onSettings}
            onLogOut={onLogOut}
            account={account}
            className={classes.userMenu}
          />
        </Group>
      </MantineHeader>
    </div>
  );
}

export default Header;
