import { useEffect, useState } from "react";
import { AppShell, Center, Loader } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconChartLine,
  IconTrophy,
  IconSoccerField,
  IconCircleCheck,
} from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "../../UI/Header";
import Navbar, { NavbarLinkProps } from "../../UI/Navbar";
import AccountService, {
  Account,
  AccountStateUnsubscriber,
} from "../../../services/account";
import {
  AUTH_ROUTE_PATH,
  HOME_ROUTE_PATH,
  MATCHES_ROUTE_PATH,
  RANKING_ROUTE_PATH,
  STATISTICS_ROUTE_PATH,
} from "../../../Router";

const LOGOUT_NOTIFICATION_ID: string = "logout";

interface HomeProps {
  onCreateAccount(event?: React.MouseEvent<HTMLElement>): void;
  onLogIn(event?: React.MouseEvent<HTMLElement>): void;
  onSettings(event?: React.MouseEvent): void;
  accountService: AccountService;
}

function HomePage({
  onCreateAccount,
  onLogIn,
  onSettings,
  accountService,
}: HomeProps) {
  const [account, setAccount] = useState<Account | null>(null);
  // Initializes a new navigation
  const navigate = useNavigate();
  // Retrieve current location
  const location = useLocation();

  let accountStateUnsubscriber: AccountStateUnsubscriber | null = null;
  // Define account state handler
  const accountStateHandler = (account: Account | null) => {
    setAccount(account);
    if (!account) {
      navigate(AUTH_ROUTE_PATH);
    }
    if (account && location.pathname === HOME_ROUTE_PATH) {
      navigate(MATCHES_ROUTE_PATH);
    }
  };
  // Unsubscribe account state event handler on unmount
  useEffect(() => {
    return () => {
      if (accountStateUnsubscriber) {
        accountStateUnsubscriber();
      }
    };
  });

  useQuery({
    queryKey: ["get-current-account"],
    queryFn: async () => {
      return await accountService.getCurrentAccount();
    },
    onSuccess: (account: Account) => {
      setAccount(account);
      const newLocation =
        location.pathname !== "/" ? location.pathname : MATCHES_ROUTE_PATH;
      navigate(newLocation);
    },
    onError: async () => {
      accountStateUnsubscriber =
        accountService.onAccountStateChanged(accountStateHandler);
      navigate(AUTH_ROUTE_PATH);
    },
  });

  const navbarLinks: NavbarLinkProps[] = [
    {
      icon: IconSoccerField,
      link: MATCHES_ROUTE_PATH,
      label: "Matchs",
      onClick: () => {
        navigate(MATCHES_ROUTE_PATH);
      },
    },
    {
      icon: IconTrophy,
      link: RANKING_ROUTE_PATH,
      label: "Classement",
      onClick: () => {
        navigate(RANKING_ROUTE_PATH);
      },
    },
    {
      icon: IconChartLine,
      link: STATISTICS_ROUTE_PATH,
      label: "Statistiques",
      onClick: () => {
        navigate(STATISTICS_ROUTE_PATH);
      },
    },
  ];

  const { mutate: logOutMutation } = useMutation({
    mutationFn: async () => {
      return await accountService.signOut();
    },
    onSuccess: () => {
      notifications.show({
        id: LOGOUT_NOTIFICATION_ID,
        color: "green",
        message: "Vous avez été déconnecté",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 3000,
      });
      setAccount(null);
      navigate(AUTH_ROUTE_PATH);
    },
  });

  return (
    <div className="home-page">
      <AppShell
        padding="md"
        header={
          <Header
            onCreateAccount={onCreateAccount}
            onLogIn={onLogIn}
            onSettings={onSettings}
            onLogOut={logOutMutation}
            account={account}
          />
        }
        navbar={
          <Navbar
            links={navbarLinks}
            height={1000}
            p="xs"
            width={{ base: 200 }}
          />
        }
        styles={(theme) => ({
          main: {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        {account == null ? (
          <Center h="100%" mx="auto">
            <Loader variant="dots" />
          </Center>
        ) : (
          // The page selected by the user is received and displayed by this placeholder component
          <Outlet />
        )}
      </AppShell>
    </div>
  );
}

export default HomePage;
export { type HomeProps };
