class SeasonAttrs {
  static readonly ID: string = "id";
  static readonly START_AT: string = "startAt";
  static readonly END_AT: string = "endAt";
  static readonly LEAGUE_ID: string = "leagueId";
}

class SeasonFilterAttrs {
  static readonly NB_SEASONS: string = "nbSeasons";
  static readonly OFFSET: string = "offset";
  static readonly LEAGUE_IDS: string = "leagueIds";
}

export default SeasonAttrs;
export { SeasonFilterAttrs };
