import { Button, Group, Text, Tooltip } from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useState } from "react";

interface ArrowButtonProps {
  tooltip: string;
  icon: JSX.Element;
  variant?: string;
  color?: string;
  radius?: string;
  onClick: React.FormEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean | undefined;
}

const ArrowButton = ({
  tooltip,
  icon,
  variant = "outline",
  color = "gray",
  radius = "md",
  onClick,
  disabled,
}: ArrowButtonProps) => {
  return (
    <Tooltip label={tooltip} withinPortal>
      <Button
        compact
        leftIcon={icon}
        variant={variant}
        color={color}
        radius={radius}
        onClick={onClick}
        disabled={disabled}
      />
    </Tooltip>
  );
};

interface InfinitePaginationProps {
  onPageChange(page: number): void;
  defaultPage?: number;
}

const InfinitePagination = ({
  onPageChange,
  defaultPage = 1,
}: InfinitePaginationProps) => {
  const [page, setPage] = useState<number>(defaultPage);

  const onPageChangeHandler = (newPage: number) => {
    if (newPage === 0) {
      return;
    }

    onPageChange(newPage);
    setPage(newPage);
  };

  const isLeftButtonDisabled = page === 1;

  return (
    <Group>
      <ArrowButton
        tooltip="Page précédente"
        icon={<IconArrowLeft />}
        disabled={isLeftButtonDisabled}
        onClick={() => onPageChangeHandler(page - 1)}
      />
      <Text>{page}</Text>
      <ArrowButton
        tooltip="Page suivante"
        icon={<IconArrowRight />}
        onClick={() => onPageChangeHandler(page + 1)}
      />
    </Group>
  );
};

export default InfinitePagination;
