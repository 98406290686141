import APIError from "../api/models/error";
import User, { UserForm, UserProfile } from "../api/models/user";
import HTTPClient from "../io/http/client";
import { HTTPResponse } from "../io/http/client";
import Service from "./base";
import { API_BASE_URL } from "../config";
import Match, {
  MatchFilter,
  MatchResult,
  MatchStatus,
} from "../api/models/match";
import {
  Headers as HTTPHeaders,
  setBearerTokenInHeaders,
} from "../io/http/header";
import {
  Ranking,
  RankingFilter,
  RankingScore,
} from "../api/models/games/ranking";
import League from "../api/models/league";
import Season, { SeasonFilter } from "../api/models/season";
import Team from "../api/models/team";
import Bet, { BetFilter, BetForm } from "../api/models/bet";
import { BetFormAttrs } from "../attrs/api/models/bet";

interface RequestParams {
  idToken: string;
}

/**
 * A service to interact with the internal API.
 */
class APIService implements Service {
  readonly name: string = "api";

  private httpClient: HTTPClient;

  constructor(httpClient: HTTPClient) {
    this.httpClient = httpClient;
  }

  private responseIsSuccess(response: HTTPResponse) {
    let statusCode = response.status;
    return statusCode >= 200 && statusCode < 300;
  }

  /**
   * Creates user.
   *
   * @param userForm user information.
   * @returns user.
   */
  async createUser(userForm: UserForm, options: RequestParams): Promise<User> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, options.idToken);

    let response = await this.httpClient.post("/users", {
      headers: headers,
      data: userForm.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return User.decode(responseData);
  }

  /**
   * Fetches user.
   *
   * @param uid user's unique identifier.
   * @returns user.
   */
  async fetchUser(uid: string, { idToken }: RequestParams): Promise<User> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/users/${uid}`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return User.decode(responseData);
  }

  /**
   * Fetches user profile.
   *
   * @param uid user's unique identifier.
   * @returns user profile.
   */
  async fetchUserProfile(
    uid: string,
    { idToken }: RequestParams
  ): Promise<UserProfile> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/users/${uid}/profile`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return UserProfile.decode(responseData);
  }

  /**
   * Fetches all leagues.
   *
   * @returns leagues
   */
  async fetchLeagues({ idToken }: RequestParams): Promise<League[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/leagues`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let leaguesData = JSON.parse(responseData) as Array<Map<string, any>>;

    let leagues = new Array<League>();
    leaguesData.forEach((leagueData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedLeagueData = JSON.stringify(leagueData);
      let league = League.decode(encodedLeagueData);
      leagues.push(league);
    });
    return leagues;
  }

  /**
   * Fetches seasons.
   *
   * @param filter filter.
   * @returns seasons
   */
  async fetchSeasons(
    filter: SeasonFilter,
    { idToken }: RequestParams
  ): Promise<Season[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/seasons`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let seasonsData = JSON.parse(responseData) as Array<Map<string, any>>;

    let seasons = new Array<Season>();
    seasonsData.forEach((seasonData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedSeasonData = JSON.stringify(seasonData);
      let season = Season.decode(encodedSeasonData);
      seasons.push(season);
    });
    return seasons;
  }

  /**
   * Fetches team.
   *
   * @param teamId team's unique identifier.
   * @returns team.
   */
  async fetchTeam(teamId: number, { idToken }: RequestParams): Promise<Team> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/teams/${teamId}`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return Team.decode(responseData);
  }

  /**
   * Fetches match.
   *
   * @param id match's unique identifier.
   * @returns match.
   */
  async fetchMatch(id: number, { idToken }: RequestParams): Promise<Match> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/matches/${id}`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return Match.decode(responseData);
  }

  /**
   * Fetches multiple matches at once.
   *
   * @param filter input filter.
   * @returns matches.
   */
  async fetchMatches(
    filter: MatchFilter,
    { idToken }: RequestParams
  ): Promise<Match[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/matches`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let matchesData = JSON.parse(responseData) as Array<Map<string, any>>;

    let matches = new Array<Match>();
    matchesData.forEach((matchData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedMatchData = JSON.stringify(matchData);
      let match = Match.decode(encodedMatchData);
      matches.push(match);
    });
    return matches;
  }

  /**
   * Fetches match status.
   *
   * @param matchId match's unique identifier.
   * @returns match status.
   */
  async fetchMatchStatus(
    matchId: number,
    { idToken }: RequestParams
  ): Promise<MatchStatus> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/matches/${matchId}/status`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return MatchStatus.decode(responseData);
  }

  /**
   * Fetches match result.
   *
   * @param matchId match's unique identifier.
   * @returns match result.
   */
  async fetchMatchResult(
    matchId: number,
    { idToken }: RequestParams
  ): Promise<MatchResult> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/matches/${matchId}/result`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return MatchResult.decode(responseData);
  }

  /**
   * Fetches a user's ranking score.
   *
   * A ranking score is the score computed after each match for each user.
   *
   * @param uid user's unique identifier.
   * @param matchId match identifier.
   * @returns ranking score.
   */
  async fetchRankingScore(
    uid: string,
    matchId: number,
    { idToken }: RequestParams
  ): Promise<RankingScore> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(
      `/games/rankings/${uid}/scores/${matchId}`,
      {
        headers: headers,
      }
    );
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return RankingScore.decode(responseData);
  }

  /**
   * Fetches user's ranking scores.
   *
   * A ranking score is the score computed after each match for each user.
   *
   * @param uid user's unique identifier.
   * @param filter filter.
   * @returns ranking scores.
   */
  async fetchRankingScores(
    uid: string,
    filter: RankingFilter,
    { idToken }: RequestParams
  ): Promise<RankingScore[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/games/rankings/${uid}/scores`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let rankingScoresData = JSON.parse(responseData) as Array<Map<string, any>>;

    let rankingScores = new Array<RankingScore>();
    rankingScoresData.forEach((rankingScoreData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedRankingScoreData = JSON.stringify(rankingScoreData);
      let rankingScore = RankingScore.decode(encodedRankingScoreData);
      rankingScores.push(rankingScore);
    });
    return rankingScores;
  }

  /**
   * Fetches user's ranking.
   *
   * @param uid user's unique identifier.
   * @param filter filter.
   * @returns ranking.
   */
  async fetchRanking(
    uid: string,
    filter: RankingFilter,
    { idToken }: RequestParams
  ): Promise<Ranking> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/games/rankings/${uid}`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return Ranking.decode(responseData);
  }

  /**
   * Fetches multiple rankings at once.
   *
   * @param filter filter.
   * @returns rankings.
   */
  async fetchRankings(
    filter: RankingFilter,
    { idToken }: RequestParams
  ): Promise<Ranking[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/games/rankings`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let rankingsData = JSON.parse(responseData) as Array<Map<string, any>>;

    let rankings = new Array<Ranking>();
    rankingsData.forEach((rankingData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedRankingData = JSON.stringify(rankingData);
      let ranking = Ranking.decode(encodedRankingData);
      rankings.push(ranking);
    });
    return rankings;
  }

  /**
   * Creates bet.
   *
   * @param betForm bet information.
   * @returns bet.
   */
  async createBet(betForm: BetForm, { idToken }: RequestParams): Promise<Bet> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.post("/bets", {
      headers: headers,
      data: betForm.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return Bet.decode(responseData);
  }

  /**
   * Fetches bet.
   *
   * @param matchId match's unique identifier.
   * @returns bet.
   */
  async fetchBet(matchId: number, { idToken }: RequestParams): Promise<Bet> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/bets/${matchId}`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
    return Bet.decode(responseData);
  }

  /**
   * Fetches multiple bets at once.
   *
   * @param filter filter.
   * @returns bets.
   */
  async fetchBets(
    filter: BetFilter,
    { idToken }: RequestParams
  ): Promise<Bet[]> {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.get(`/bets`, {
      headers: headers,
      params: filter.encode(),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }

    let betssData = JSON.parse(responseData) as Array<Map<string, any>>;

    let bets = new Array<Bet>();
    betssData.forEach((betData) => {
      // This is f****** stupid to re-encode an already decoded object but we have no other choice
      let encodedBetData = JSON.stringify(betData);
      let bet = Bet.decode(encodedBetData);
      bets.push(bet);
    });
    return bets;
  }

  /**
   * Deletes bet.
   *
   * @param matchId match's unique identifier.
   */
  async deleteBet(matchId: number, { idToken }: RequestParams) {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    let response = await this.httpClient.delete(`/bets/${matchId}`, {
      headers: headers,
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
  }

  /**
   * Updates bet.
   *
   * @param betForm bet information.
   */
  async updateBet(betForm: BetForm, { idToken }: RequestParams) {
    let headers: HTTPHeaders = {};
    setBearerTokenInHeaders(headers, idToken);

    // Match ID must not be provided when updating a bet
    const betUpdateForm = JSON.parse(betForm.encode());
    delete betUpdateForm[BetFormAttrs.MATCH_ID];

    let response = await this.httpClient.patch(`/bets/${betForm.matchId}`, {
      headers: headers,
      data: JSON.stringify(betUpdateForm),
    });
    let responseData = response.data;
    if (!this.responseIsSuccess(response)) {
      throw APIError.decode(responseData);
    }
  }
}

/**
 * Defaults API service.
 */
let apiService: APIService = new APIService(new HTTPClient(API_BASE_URL));

export default apiService;
export { APIService };
