/**
 * Computes the age of a person based on their date of birth.
 *
 * @param birthDate input birth date.
 * @returns age.
 */
function computeAge(birthDate: Date): number {
  let today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (
    today.getMonth() <= birthDate.getMonth() &&
    today.getDate() < birthDate.getDate()
  ) {
    age = age - 1;
  }
  return age;
}

/**
 * Given a person's date of birth, checks that they are over a specific age.
 *
 * @param birthDate input birth date.
 * @param age input age.
 * @returns `true` if the condition is met, otherwise `false`.
 */
function checkAge(birthDate: Date, age: number): boolean {
  return computeAge(birthDate) >= age;
}

/**
 * Returns the earliest date of birth for a given age.
 *
 * @param age input age.
 * @returns birth date.
 */
function getBirthDate(age: number): Date {
  let birthDate = new Date();
  let birthDateYear = birthDate.getFullYear() - age;
  birthDate.setFullYear(birthDateYear);
  return birthDate;
}

export { computeAge, checkAge, getBirthDate };
