import APIModel from "./base";
import InternalErrorAttrs from "../../attrs/api/models/error";
import { EncodedAPIModel } from "./encoding";

/**
 * Enumeration of internal API error codes.
 */
enum InternalErrorCode {
  INTERNAL_ERROR,
  INVALID_REQUEST,
  INVALID_BEARER_TOKEN,
  INVALID_TOKEN,
  MISSING_TOKEN,
  EXPIRED_TOKEN,
  REVOKED_TOKEN,
  INSUFFICIENT_PERMISSION,
  BANNED_USER,
  MISSING_JSON_PART,
  INVALID_JSON_KEYS,
  MISSING_QUERY_STRING_PART,
  INVALID_QUERY_STRING_KEYS,
  UNKNOWN_USER,
  UNKNOWN_LEAGUE,
  UNKNOWN_MATCH,
  UNKNOWN_MATCH_RESULT,
  UNKNOWN_MATCH_STATUS,
  UNKNOWN_BET,
  UNKNOWN_BET_RESULT,
  UNKNOWN_TEAM,
  UNKNOWN_SEASON,
  UNKNOWN_REGION,
  UNKNOWN_SURVIVOR,
  UNKNOWN_SURVIVOR_PHASE,
  UNKNOWN_SURVIVOR_PLAYER,
  UNKNOWN_SURVIVOR_PLAYER_STATS,
  UNKNOWN_SURVIVOR_BET,
  UNKNOWN_RANKING_SCORE,
  PROGNOSIS_ALREADY_EXISTS,
  PROGNOSIS_LOCKED,
  PROGNOSIS_MATCH_STARTED,
  PROGNOSIS_MATCH_OVER,
}

/**
 * Representation of an internal API error.
 */
class APIError extends Error implements APIModel {
  code: InternalErrorCode;
  message: string;

  constructor(code: InternalErrorCode, message: string) {
    super();

    this.code = code;
    this.message = message;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[InternalErrorAttrs.CODE] = this.code;
    content[InternalErrorAttrs.MESSAGE] = this.message;
    return JSON.stringify(content);
  }

  static decode(content: string): APIError {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new APIError(
      decodedContent[InternalErrorAttrs.CODE],
      decodedContent[InternalErrorAttrs.MESSAGE]
    );
  }

  /**
   * Converts API error to string.
   * 
   * @returns API error message.
   */
  toString(): string {
    return this.message;
  }
}

export default APIError;
