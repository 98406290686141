import { Route, Routes, useNavigate } from "react-router-dom";

import AccountService, { Account } from "./services/account";
import { APIService } from "./services/api";
import RegistrationPage from "./components/Core/Pages/RegistrationPage";
import AuthenticationPage from "./components/Core/Pages/AuthenticationPage";
import SettingsPage from "./components/Core/Pages/SettingsPage";
import HomePage from "./components/Core/Pages/HomePage";
import StatisticsPage from "./components/Core/Pages/StatisticsPage";
import RankingPage from "./components/Core/Pages/RankingPage";
import MatchesPage from "./components/Core/Pages/MatchesPage";

const HOME_ROUTE_PATH = "/";
const AUTH_ROUTE_PATH = "/auth";
const REGISTER_ROUTE_PATH = "/register";
const SETTINGS_ROUTE_PATH = "/settings";
const MATCHES_ROUTE_PATH = "/matches";
const STATISTICS_ROUTE_PATH = "/statistics";
const RANKING_ROUTE_PATH = "/ranking";

interface RouterProps {
  accountService: AccountService;
  apiService: APIService;
}

function Router({ accountService, apiService }: RouterProps) {
  // Initializes a new navigation
  const navigate = useNavigate();

  const accountConnectionSuccessHandler = (account: Account) => {
    navigate(HOME_ROUTE_PATH);
  };
  const accountCreationSuccessHandler = (account: Account) => {
    navigate(HOME_ROUTE_PATH);
  };
  const accountCreationCancelHandler = () => {
    navigate(HOME_ROUTE_PATH);
  };
  const navigateToAuthenticationPage = () => {
    navigate(AUTH_ROUTE_PATH);
  };
  const navigateToRegistrationPage = () => {
    navigate(REGISTER_ROUTE_PATH);
  };
  const navigateToSettingsPage = () => {
    navigate(SETTINGS_ROUTE_PATH);
  };

  return (
    <Routes>
      <Route
        path={HOME_ROUTE_PATH}
        element={
          <HomePage
            onCreateAccount={navigateToRegistrationPage}
            onLogIn={navigateToAuthenticationPage}
            onSettings={navigateToSettingsPage}
            accountService={accountService}
          />
        }
      >
        <Route
          path={MATCHES_ROUTE_PATH}
          element={
            <MatchesPage
              accountService={accountService}
              apiService={apiService}
            />
          }
        />
        <Route
          path={RANKING_ROUTE_PATH}
          element={
            <RankingPage
              accountService={accountService}
              apiService={apiService}
            />
          }
        />
        <Route
          path={STATISTICS_ROUTE_PATH}
          element={
            <StatisticsPage
              accountService={accountService}
              apiService={apiService}
            />
          }
        />
      </Route>
      <Route
        path={REGISTER_ROUTE_PATH}
        element={
          <RegistrationPage
            accountService={accountService}
            onCreateAccount={accountCreationSuccessHandler}
            onCancelCreation={accountCreationCancelHandler}
          />
        }
      />
      <Route
        path={AUTH_ROUTE_PATH}
        element={
          <AuthenticationPage
            accountService={accountService}
            onSuccess={accountConnectionSuccessHandler}
            onRegister={navigateToRegistrationPage}
          />
        }
      />
      <Route
        path={SETTINGS_ROUTE_PATH}
        element={<SettingsPage accountService={accountService} />}
      />
    </Routes>
  );
}

export default Router;
export {
  HOME_ROUTE_PATH,
  AUTH_ROUTE_PATH,
  REGISTER_ROUTE_PATH,
  SETTINGS_ROUTE_PATH,
  MATCHES_ROUTE_PATH,
  STATISTICS_ROUTE_PATH,
  RANKING_ROUTE_PATH,
};
