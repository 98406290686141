import { useQueries } from "@tanstack/react-query";

import Team from "../api/models/team";
import AccountService from "../services/account";
import { APIService } from "../services/api";
import Match, { MatchResult, MatchStatus } from "../api/models/match";
import { UserProfile } from "../api/models/user";

type HookStatus = "loading" | "partial" | "success";

function guessHookStatus(ids: Array<any>, result: Array<any>): HookStatus {
  if (result.length === 0) {
    return "loading";
  }
  if (result.length < ids.length) {
    return "partial";
  }
  return "success";
}

interface HookResult<T> {
  status: HookStatus;
  data: T;
}

/**
 * A hook to fetch multiple teams at once.
 *
 * @param teamIds team IDs.
 * @param accountService account service.
 * @param apiService API service.
 * @returns teams.
 */
function useTeams(
  teamIds: number[],
  accountService: AccountService,
  apiService: APIService
): HookResult<Team[]> {
  const teamQueries = useQueries({
    queries: teamIds.map((teamId) => {
      return {
        queryKey: ["team", teamId],
        queryFn: async () => {
          let idToken = await accountService.generateIDToken();
          return await apiService.fetchTeam(teamId, { idToken: idToken });
        },
      };
    }),
  });

  let teams: Team[] = teamQueries
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data) as Team[];

  return { status: guessHookStatus(teamIds, teams), data: teams };
}

/**
 * A hook to fetch multiple matches at once.
 *
 * @param matchIds match IDs.
 * @param accountService account service.
 * @param apiService API service.
 * @returns matches.
 */
function useMatches(
  matchIds: number[],
  accountService: AccountService,
  apiService: APIService
): HookResult<Match[]> {
  const matchQueries = useQueries({
    queries: matchIds.map((matchId) => {
      return {
        queryKey: ["match", matchId],
        queryFn: async () => {
          let idToken = await accountService.generateIDToken();
          return await apiService.fetchMatch(matchId, { idToken: idToken });
        },
      };
    }),
  });
  let matches: Match[] = matchQueries
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data) as Match[];

  return { status: guessHookStatus(matchIds, matches), data: matches };
}

/**
 * A hook to fetch multiple match statuses at once.
 *
 * @param matchIds match IDs.
 * @param accountService account service.
 * @param apiService API service.
 * @returns match statuses.
 */
function useMatchStatuses(
  matchIds: number[],
  accountService: AccountService,
  apiService: APIService
): HookResult<MatchStatus[]> {
  const matchStatusQueries = useQueries({
    queries: matchIds.map((matchId) => {
      return {
        queryKey: ["match", matchId, "status"],
        queryFn: async () => {
          let idToken = await accountService.generateIDToken();
          return await apiService.fetchMatchStatus(matchId, {
            idToken: idToken,
          });
        },
      };
    }),
  });
  let matchStatuses: MatchStatus[] = matchStatusQueries
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data) as MatchStatus[];

  return {
    status: guessHookStatus(matchIds, matchStatuses),
    data: matchStatuses,
  };
}

/**
 * A hook to fetch multiple match results at once.
 *
 * @param matchIds match IDs.
 * @param accountService account service.
 * @param apiService API service.
 * @returns match results.
 */
function useMatchResults(
  matchIds: number[],
  accountService: AccountService,
  apiService: APIService
): HookResult<MatchResult[]> {
  const matchResultsQueries = useQueries({
    queries: matchIds.map((matchId) => {
      return {
        queryKey: ["match", matchId, "result"],
        queryFn: async () => {
          let idToken = await accountService.generateIDToken();
          return await apiService.fetchMatchResult(matchId, {
            idToken: idToken,
          });
        },
        retry: false,
      };
    }),
  });
  let matchResults: MatchResult[] = matchResultsQueries
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data) as MatchResult[];

  return {
    status: guessHookStatus(matchIds, matchResults),
    data: matchResults,
  };
}

/**
 * A hook to fetch multiple user profiles at once.
 *
 * @param uids users' unique identifier.
 * @param accountService account service.
 * @param apiService API service
 * @returns user profiles.
 */
function useUserProfiles(
  uids: string[],
  accountService: AccountService,
  apiService: APIService
): HookResult<UserProfile[]> {
  const teamQueries = useQueries({
    queries: uids.map((uid) => {
      return {
        queryKey: ["user", uid, "profile"],
        queryFn: async () => {
          let idToken = await accountService.generateIDToken();
          return await apiService.fetchUserProfile(uid, { idToken: idToken });
        },
      };
    }),
  });

  let userProfiles: UserProfile[] = teamQueries
    .filter(({ data }) => data !== undefined)
    .map(({ data }) => data) as UserProfile[];

  return { status: guessHookStatus(uids, userProfiles), data: userProfiles };
}

export {
  useMatches,
  useMatchResults,
  useMatchStatuses,
  useTeams,
  useUserProfiles,
};
