import { useState } from "react";
import {
  Navbar as MantineNavbar,
  Text,
  createStyles,
  getStylesRef,
} from "@mantine/core";

import { TablerIconsProps } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },
}));

interface NavbarLinkProps {
  label: string;
  link: string;
  icon(props: TablerIconsProps): JSX.Element;
  onClick(): void;
}

interface NavbarProps {
  links: NavbarLinkProps[];
  [key: string]: any;
}

function Navbar({ links, ...rest }: NavbarProps) {
  const { classes, cx } = useStyles();

  // Retrieve the current location...
  const location = useLocation();
  // ... to set the default active link
  let defaultActiveLink = links
    .filter((link) => {
      return link.link === location.pathname;
    })
    .at(0)?.label;
  if (defaultActiveLink === undefined) {
    defaultActiveLink = links[0].label;
  }

  const [activeLink, setActiveLink] = useState(defaultActiveLink);

  const navbarlinks = links.map((link) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: link.label === activeLink,
      })}
      href={link.link}
      key={link.label}
      onClick={(event) => {
        event.preventDefault();
        setActiveLink(link.label);
        link.onClick();
      }}
    >
      <link.icon className={classes.linkIcon} stroke={1.5} />
      <span>{link.label}</span>
    </a>
  ));

  return (
    <div className="navbar">
      <MantineNavbar {...rest}>
        <Text color="dimmed" size="xs" transform="uppercase" weight={500}>
          Navigation
        </Text>
        {navbarlinks}
      </MantineNavbar>
    </div>
  );
}

export default Navbar;
export { type NavbarProps, type NavbarLinkProps };
