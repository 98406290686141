import {
  Image,
  SegmentedControl,
  Paper,
  Text,
  Title,
  Group,
  Space,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import League from "../../api/models/league";
import { IconCalendar } from "@tabler/icons-react";
import { useState } from "react";
import { getDateAtMidnight } from "../../utils/date";

interface MatchesFiltersProps {
  leagues: League[];
  defaultLeagueId?: number;
  onLeagueIdChange(leagueId: number): void;
  onStartAfterChange(startAfter: Date | null): void;
}

function MatchesFilters({
  leagues,
  defaultLeagueId,
  onLeagueIdChange,
  onStartAfterChange,
}: MatchesFiltersProps) {
  const LeagueLabel = (leagueName: string, leagueCrest: string) => {
    return (
      <Group>
        <Image maw="1rem" withPlaceholder src={leagueCrest} />
        <Space w="0.2rem" />
        <Text>{leagueName}</Text>
      </Group>
    );
  };

  const [selectedLeagueId, setSelectedLeagueId] = useState<number | undefined>(
    defaultLeagueId
  );

  const leagueIdChangeHandler = (leagueId: number) => {
    setSelectedLeagueId(leagueId);
    onLeagueIdChange(leagueId);
  };

  const leagueSegmentedControlData: Array<{
    label: JSX.Element;
    value: string;
  }> = [];
  leagues.forEach((league) => {
    leagueSegmentedControlData.push({
      label: LeagueLabel(league.name, league.crest),
      value: league.id.toString(),
    });
  });

  return (
    <div className="match-filters">
      <Title order={4}>Filtres</Title>
      <Space h="1rem" />
      <Paper withBorder shadow="xs" p="md" radius="lg">
        <DateInput
          label="Choix de la date"
          description="Afficher les matchs à partir de la date choisie"
          placeholder=""
          maw={400}
          onChange={(value) => {
            if (value === null) {
              value = getDateAtMidnight();
            }
            onStartAfterChange(value);
          }}
          clearable
          defaultDate={getDateAtMidnight()}
          defaultValue={getDateAtMidnight()}
          icon={<IconCalendar size="1rem" />}
        />
        <Space h="1rem" />
        <Text fw={500} fz="md">
          Sélection de la ligue
        </Text>
        <SegmentedControl
          orientation="vertical"
          fullWidth={true}
          data={leagueSegmentedControlData}
          value={selectedLeagueId?.toString()}
          onChange={(value) => leagueIdChangeHandler(Number(value))}
          style={{ backgroundColor: "white" }}
        />
      </Paper>
    </div>
  );
}

export default MatchesFilters;
export { type MatchesFiltersProps };
