import { Center } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";

import UserForm, { UserFormFields } from "../UserForm";
import AccountService, { Account } from "../../../services/account";

const USER_FORM_NOTIFICATION_ID: string = "send-user-form";

interface RegistrationPageProps {
  accountService: AccountService;
  onCreateAccount(account: Account): void;
  onCancelCreation(): void;
}

function RegistrationPage({
  accountService,
  onCreateAccount,
  onCancelCreation,
}: RegistrationPageProps) {
  const registrationMutation = useMutation({
    mutationFn: async (fields: UserFormFields): Promise<Account> => {
      return await accountService.create(
        fields.email,
        fields.password,
        fields.name,
        fields.surname,
        fields.birthDate,
        null,
        fields.username
      );
    },
    onMutate: () => {
      notifications.show({
        id: USER_FORM_NOTIFICATION_ID,
        loading: true,
        message: "Compte en cours de création, veuillez patienter",
        autoClose: false,
      });
    },
    onSuccess: (account: Account) => {
      notifications.update({
        id: USER_FORM_NOTIFICATION_ID,
        color: "green",
        message: "Compte créé avec succès",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 5000,
      });
      onCreateAccount(account);
    },
    onError: (error: Error) => {
      notifications.update({
        id: USER_FORM_NOTIFICATION_ID,
        color: "red",
        title: "Une erreur est apparue lors de la création du compte",
        message: error.toString(),
        icon: <IconCircleX size="1rem" />,
        autoClose: 5000,
      });
    },
  });

  return (
    <div className="register-page">
      <h1>Création du compte</h1>
      <Center maw={1000} mx="auto">
        <UserForm
          onSubmit={registrationMutation.mutate}
          onCancel={onCancelCreation}
        />
      </Center>
    </div>
  );
}

export default RegistrationPage;
export { type RegistrationPageProps };
