import APIModel from "./base";
import MatchAttrs from "../../attrs/api/models/match";
import {
  MatchStatusAttrs,
  MatchResultAttrs,
  MatchFilterAttrs,
} from "../../attrs/api/models/match";
import { EncodedAPIModel } from "./encoding";
import Season from "./season";
import League from "./league";
import Team from "./team";

/**
 * Enumeration of all possible match statuses.
 */
enum MatchStatuses {
  SCHEDULED,
  IN_PLAY,
  PAUSED,
  POSTPONED,
  SUSPENDED,
  CANCELED,
  FINISHED,
  AWARDED,
  TIMED,
  UNKNOWN,
}

class Match implements APIModel {
  id: number;
  season: Season;
  league: League;
  homeTeam: Team;
  awayTeam: Team;
  day: number;
  status: MatchStatus;
  result: MatchResult | null;

  constructor(
    id: number,
    season: Season,
    league: League,
    homeTeam: Team,
    awayTeam: Team,
    day: number,
    status: MatchStatus,
    result: MatchResult | null
  ) {
    this.id = id;
    this.season = season;
    this.league = league;
    this.homeTeam = homeTeam;
    this.awayTeam = awayTeam;
    this.day = day;
    this.status = status;
    this.result = result;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[MatchAttrs.ID] = this.id;
    content[MatchAttrs.SEASON] = this.season;
    content[MatchAttrs.LEAGUE] = this.league;
    content[MatchAttrs.HOME_TEAM] = this.homeTeam;
    content[MatchAttrs.AWAY_TEAM] = this.awayTeam;
    content[MatchAttrs.DAY] = this.day;
    content[MatchAttrs.STATUS] = this.status;
    content[MatchAttrs.RESULT] = this.result;
    return JSON.stringify(content);
  }

  static decode(content: string): Match {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;

    // Match result can be null so parsing is...
    const decodedMatchResult: Map<string, any> | null =
      decodedContent[MatchAttrs.RESULT];
    const matchResult =
      decodedMatchResult !== null
        ? MatchResult.decode(JSON.stringify(decodedMatchResult))
        : null;

    return new Match(
      decodedContent[MatchAttrs.ID],
      Season.decode(JSON.stringify(decodedContent[MatchAttrs.SEASON])),
      League.decode(JSON.stringify(decodedContent[MatchAttrs.LEAGUE])),
      Team.decode(JSON.stringify(decodedContent[MatchAttrs.HOME_TEAM])),
      Team.decode(JSON.stringify(decodedContent[MatchAttrs.AWAY_TEAM])),
      decodedContent[MatchAttrs.DAY],
      MatchStatus.decode(JSON.stringify(decodedContent[MatchAttrs.STATUS])),
      matchResult
    );
  }
}

class MatchStatus implements APIModel {
  id: number;
  matchId: number;
  startAt: Date;
  status: MatchStatuses;

  constructor(
    id: number,
    matchId: number,
    startAt: Date,
    status: MatchStatuses
  ) {
    this.id = id;
    this.matchId = matchId;
    this.startAt = startAt;
    this.status = status;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[MatchStatusAttrs.ID] = this.id;
    content[MatchStatusAttrs.MATCH_ID] = this.matchId;
    content[MatchStatusAttrs.START_AT] = this.startAt.toISOString();
    content[MatchStatusAttrs.STATUS] = this.status;
    return JSON.stringify(content);
  }

  static decode(content: string): MatchStatus {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    let startAtRaw: string = decodedContent[MatchStatusAttrs.START_AT];
    let statusRaw: string = decodedContent[MatchStatusAttrs.STATUS];
    return new MatchStatus(
      decodedContent[MatchStatusAttrs.ID],
      decodedContent[MatchStatusAttrs.MATCH_ID],
      new Date(startAtRaw),
      MatchStatuses[statusRaw as keyof typeof MatchStatuses]
    );
  }

  /**
   * @returns human-readable version of the status
   */
  humazine(): string {
    switch (this.status) {
      case MatchStatuses.SCHEDULED:
        return "Programmé";
      case MatchStatuses.IN_PLAY:
        return "En cours";
      case MatchStatuses.PAUSED:
        return "Mi-temps";
      case MatchStatuses.POSTPONED:
        return "Reporté";
      case MatchStatuses.SUSPENDED:
        return "Suspendu";
      case MatchStatuses.CANCELED:
        return "Annulé";
      case MatchStatuses.FINISHED:
        return "Terminé";
      case MatchStatuses.AWARDED:
        return "Terminé";
      case MatchStatuses.TIMED:
        return "Programmé";
      case MatchStatuses.UNKNOWN:
      default:
        return "Inconnu";
    }
  }
}

class MatchResult implements APIModel {
  id: number;
  matchId: number;
  homeScore: number;
  awayScore: number;

  constructor(
    id: number,
    matchId: number,
    homeScore: number,
    awayScore: number
  ) {
    this.id = id;
    this.matchId = matchId;
    this.homeScore = homeScore;
    this.awayScore = awayScore;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[MatchResultAttrs.ID] = this.id;
    content[MatchResultAttrs.MATCH_ID] = this.matchId;
    content[MatchResultAttrs.HOME_SCORE] = this.homeScore;
    content[MatchResultAttrs.AWAY_SCORE] = this.awayScore;
    return JSON.stringify(content);
  }

  static decode(content: string): MatchResult {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new MatchResult(
      decodedContent[MatchResultAttrs.ID],
      decodedContent[MatchResultAttrs.MATCH_ID],
      decodedContent[MatchResultAttrs.HOME_SCORE],
      decodedContent[MatchResultAttrs.AWAY_SCORE]
    );
  }
}

class MatchFilter implements APIModel {
  nbMatches: number;
  offset: number;
  leagueId: number | null;
  seasonId: number | null;
  startBefore: Date | null;
  startAfter: Date | null;

  constructor(
    nbMatches: number,
    offset: number,
    leagueId: number | null,
    seasonId: number | null,
    startBefore: Date | null,
    startAfter: Date | null
  ) {
    this.nbMatches = nbMatches;
    this.offset = offset;
    this.leagueId = leagueId;
    this.seasonId = seasonId;
    this.startBefore = startBefore;
    this.startAfter = startAfter;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[MatchFilterAttrs.NB_MATCHES] = this.nbMatches;
    content[MatchFilterAttrs.OFFSET] = this.offset;
    if (this.leagueId !== null) {
      content[MatchFilterAttrs.LEAGUE_ID] = this.leagueId;
    }
    if (this.seasonId !== null) {
      content[MatchFilterAttrs.SEASON_ID] = this.seasonId;
    }
    if (this.startBefore !== null) {
      content[MatchFilterAttrs.START_BEFORE] = this.startBefore.toISOString();
    }
    if (this.startAfter !== null) {
      content[MatchFilterAttrs.START_AFTER] = this.startAfter.toISOString();
    }
    return JSON.stringify(content);
  }

  static decode(content: string): MatchFilter {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    let startBeforeRaw: string | null =
      decodedContent[MatchFilterAttrs.START_BEFORE];
    let startAfterRaw: string | null =
      decodedContent[MatchFilterAttrs.START_AFTER];
    let startBefore: Date | null = null;
    let startAfter: Date | null = null;
    if (startBeforeRaw !== null) {
      startBefore = new Date(startBeforeRaw);
    }
    if (startAfterRaw !== null) {
      startAfter = new Date(startAfterRaw);
    }

    return new MatchFilter(
      decodedContent[MatchFilterAttrs.NB_MATCHES],
      decodedContent[MatchFilterAttrs.OFFSET],
      decodedContent[MatchFilterAttrs.LEAGUE_ID],
      decodedContent[MatchFilterAttrs.SEASON_ID],
      startBefore,
      startAfter
    );
  }
}

export default Match;
export { MatchStatuses, MatchStatus, MatchResult, MatchFilter };
