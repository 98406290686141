import { FirebaseApp } from "firebase/app";
import {
  Auth as FirebaseAuthApp,
  inMemoryPersistence,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  browserLocalPersistence,
} from "firebase/auth";

import Service from "./base";
import { APIService } from "./api";
import { UserForm } from "../api/models/user";

/**
 * Account information.
 */
interface Account {
  uid: string;
  email: string;
  emailVerified: boolean;
  name: string;
  surname: string;
  birthDate: Date;
  photoUrl: string | null;
  username: string;
}

/**
 * A service to manage user account.
 */
class AccountService implements Service {
  readonly name: string = "account";

  private firebaseAuthApp: FirebaseAuthApp;
  private apiService: APIService;
  private currentAccount: Account | null;

  constructor(firebaseApp: FirebaseApp, apiService: APIService) {
    this.firebaseAuthApp = getAuth(firebaseApp);
    this.apiService = apiService;
    this.currentAccount = null;
  }

  /**
   * Creates a new account.
   *
   * @param email the user's email address.
   * @param password the user's password.
   * @param name the user's name.
   * @param surname the user's surname.
   * @param birthDate the user's birth date.
   * @param photoUrl an optional photo URL.
   * @param username unique username.
   * @returns account information.
   */
  async create(
    email: string,
    password: string,
    name: string,
    surname: string,
    birthDate: Date,
    photoUrl: string | null,
    username: string
  ): Promise<Account> {
    let userCredential = await createUserWithEmailAndPassword(
      this.firebaseAuthApp,
      email,
      password
    );
    let firebaseUser = userCredential.user;

    let userForm = new UserForm(
      firebaseUser.uid,
      name,
      surname,
      birthDate,
      email,
      photoUrl,
      username
    );
    let idToken = await this.generateIDToken();
    let user = await this.apiService.createUser(userForm, {
      idToken: idToken,
    });

    let account = {
      ...user,
      ...{ emailVerified: firebaseUser.emailVerified },
    };
    this.currentAccount = account;
    return account;
  }

  /**
   * Signs in using an existing account.
   *
   * @param email the user's email address.
   * @param password the user's password.
   * @returns account information.
   */
  async signIn(email: string, password: string): Promise<Account> {
    let userCredential = await signInWithEmailAndPassword(
      this.firebaseAuthApp,
      email,
      password
    );
    let firebaseUser = userCredential.user;

    // Retrieves additional user information from the API
    let idToken = await this.generateIDToken();
    let user = await this.apiService.fetchUser(firebaseUser.uid, {
      idToken: idToken,
    });

    let account = {
      ...user,
      ...{ emailVerified: firebaseUser.emailVerified },
    };
    this.currentAccount = account;
    return account;
  }

  /**
   * Signs out the current user.
   */
  async signOut() {
    this.currentAccount = null;
    await this.firebaseAuthApp.signOut();
  }

  isSignedIn(): boolean {
    return this.firebaseAuthApp.currentUser !== null;
  }

  async getCurrentAccount(): Promise<Account> {
    if (this.currentAccount !== null) {
      return this.currentAccount;
    }

    let firebaseUser = this.firebaseAuthApp.currentUser;
    if (firebaseUser === null) {
      throw new Error(
        "Unable to retrieve account information without a user logged in"
      );
    }
    // Retrieves additional user information from the API
    let idToken = await this.generateIDToken();
    let user = await this.apiService.fetchUser(firebaseUser.uid, {
      idToken: idToken,
    });

    let currentAccount = {
      ...user,
      ...{ emailVerified: firebaseUser.emailVerified },
    };
    this.currentAccount = currentAccount;
    return currentAccount;
  }

  /**
   * Generates an ID token for the currently logged-in user.
   *
   * An error is thrown if no user is logged in.
   *
   * @returns ID token.
   */
  async generateIDToken(): Promise<string> {
    let currentUser = this.firebaseAuthApp.currentUser;
    if (currentUser === null) {
      throw new Error(
        "Unable to generate an ID token without a user logged in"
      );
    }
    return await currentUser.getIdToken();
  }

  /**
   * Sets whether account must persist in local storage or not.
   */
  setPersistence(persistence: boolean): void {
    // Disable persistence by using the in memory implementation
    if (!persistence) {
      this.firebaseAuthApp.setPersistence(inMemoryPersistence);
    } else {
      this.firebaseAuthApp.setPersistence(browserLocalPersistence);
    }
  }

  onAccountStateChanged(
    receiver: (account: Account | null) => void
  ): AccountStateUnsubscriber {
    return this.firebaseAuthApp.onAuthStateChanged(async (user) => {
      if (user) {
        await this.getCurrentAccount();
      } else {
        this.currentAccount = null;
      }
      receiver(this.currentAccount);
    });
  }
}

type AccountStateUnsubscriber = () => void;

export default AccountService;
export { type Account, type AccountStateUnsubscriber };
