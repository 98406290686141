const PASSWORD_LENGTH_MIN = 12;
const PASSWORD_LENGTH_MAX = 32;
const PASSWORD_REQUIREMENTS = {
  digit: {
    // contains at least one single digit from 0 to 9
    re: "(?=.*[0-9])",
    label: "Includes number",
  },
  lowercase: {
    // contains at least one lowercase letter
    re: "(?=.*[a-z])",
    label: "Includes lowercase letter",
  },
  uppercase: {
    // contains at least one uppercase letter
    re: "(?=.*[A-Z])",
    label: "Includes uppercase letter",
  },
  special: {
    // contains at least one special character
    re: "(?=.*\\W)",
    label: "Includes special character",
  },
  length: {
    // a number of characters between 12 and 32
    re: `.{${PASSWORD_LENGTH_MIN},${PASSWORD_LENGTH_MAX}}`,
    label: `Length of ${PASSWORD_LENGTH_MIN}-${PASSWORD_LENGTH_MAX} characters`,
  },
};
const PASSWORD_NB_REQUIREMENTS = Object.keys(PASSWORD_REQUIREMENTS).length;
const PASSWORD_REGEX = new RegExp(
  "^" +
    PASSWORD_REQUIREMENTS.digit.re +
    PASSWORD_REQUIREMENTS.lowercase.re +
    PASSWORD_REQUIREMENTS.uppercase.re +
    PASSWORD_REQUIREMENTS.special.re +
    PASSWORD_REQUIREMENTS.length.re +
    "$"
);

/**
 * Checks that a password is strong enough.
 *
 * A password is considered as strong iff it contains:
 * * at least one single digit from 0 to 9;
 * * at least one lowercase letter;
 * * at least one uppercase letter;
 * * at least one special character;
 * * a number of characters between 12 and 32.
 *
 * @param password input password.
 */
function checkPasswordStrength(password: string): boolean {
  return PASSWORD_REGEX.test(password);
}

/**
 * Gets password strength, in percent.
 *
 * @param password input password
 */
function getPasswordStrength(password: string): number {
  let multiplier = 0;

  Object.values(PASSWORD_REQUIREMENTS).forEach((requirement) => {
    let requirementRegex = new RegExp(requirement.re);
    if (!requirementRegex.test(password)) {
      multiplier += 1;
    }
  });

  return 100 - (100 / PASSWORD_NB_REQUIREMENTS) * multiplier;
}

export { PASSWORD_REQUIREMENTS, checkPasswordStrength, getPasswordStrength };
