import { ReactElement, useEffect, useReducer, useState } from "react";
import {
  Button,
  Center,
  Group,
  Image,
  NumberInput,
  Paper,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { IconCircleCheck, IconCircleX, IconReceipt } from "@tabler/icons-react";

import AccountService from "../../services/account";
import { APIService } from "../../services/api";
import BetCategorySelect from "./BetCategorySelect";
import Bet, {
  BetForm,
  OddCategory,
  humanizeOddCategory,
} from "../../api/models/bet";
import BetScore from "./BetScore";
import Match, { MatchStatuses } from "../../api/models/match";

const BET_CREATION_NOTIFICATION_ID: string = "bet-creation-notification";
const BET_UPDATE_NOTIFICATION_ID: string = "bet-update-notification";
const BET_DELETION_NOTIFICATION_ID: string = "bet-deletion-notification";

function matchLocked(match: Match): boolean {
  if (match.result !== null) {
    return true;
  }
  const matchStatus = match.status.status;
  return [
    MatchStatuses.IN_PLAY,
    MatchStatuses.PAUSED,
    MatchStatuses.SUSPENDED,
    MatchStatuses.FINISHED,
    MatchStatuses.AWARDED,
  ].includes(matchStatus);
}

interface ButtonLoadingState {
  create: boolean;
  update: boolean;
  delete: boolean;
}

type ButtonReducerActionType = "create" | "update" | "delete";
interface ButtonReducerAction {
  type: ButtonReducerActionType;
  isLoading: boolean;
}

function buttonLoadingReducer(
  state: ButtonLoadingState,
  action: ButtonReducerAction
): ButtonLoadingState {
  const newState = { create: false, update: false, delete: false };
  switch (action.type) {
    case "create":
      return { ...newState, create: action.isLoading };
    case "update":
      return { ...newState, update: action.isLoading };
    case "delete":
      return { ...newState, delete: action.isLoading };
    default:
      throw new Error(`Unsupported button action type '${action.type}'`);
  }
}

function filterOddCategories(
  homeTeamScore: number | null,
  awayTeamScore: number | null
): OddCategory[] {
  if (homeTeamScore === null || awayTeamScore === null) {
    return [
      OddCategory.ODD_C1,
      OddCategory.ODD_CN,
      OddCategory.ODD_C2,
      OddCategory.ODD_1N,
      OddCategory.ODD_N2,
      OddCategory.ODD_12,
    ];
  }
  if (homeTeamScore > awayTeamScore) {
    return [OddCategory.ODD_C1, OddCategory.ODD_1N, OddCategory.ODD_12];
  }
  if (homeTeamScore < awayTeamScore) {
    return [OddCategory.ODD_C2, OddCategory.ODD_N2, OddCategory.ODD_12];
  }
  return [OddCategory.ODD_CN, OddCategory.ODD_1N, OddCategory.ODD_N2];
}

interface BetManagementProps {
  accountService: AccountService;
  apiService: APIService;
  match: Match | null;
}

function BetManagement({
  accountService,
  apiService,
  match,
}: BetManagementProps) {
  const matchId = match?.id;
  const [buttonIsLoading, buttonIsLoadingDispatch] = useReducer(
    buttonLoadingReducer,
    { create: false, update: false, delete: false }
  );

  const [bet, setBet] = useState<Bet | null>(null);

  const { mutate: betCreateMutation } = useMutation({
    mutationFn: async () => {
      const idToken = await accountService.generateIDToken();
      const betForm = new BetForm(
        matchId!,
        oddCategory,
        scoresProvided ? homeTeamScore : null,
        scoresProvided ? awayTeamScore : null
      );
      return await apiService.createBet(betForm, { idToken: idToken });
    },
    onMutate: () => {
      buttonIsLoadingDispatch({ type: "create", isLoading: true });
      notifications.show({
        id: BET_CREATION_NOTIFICATION_ID,
        loading: true,
        message: "Pari en cours de création, veuillez patienter",
        autoClose: false,
      });
    },
    onSuccess: (data: Bet) => {
      buttonIsLoadingDispatch({ type: "create", isLoading: false });
      notifications.update({
        id: BET_CREATION_NOTIFICATION_ID,
        color: "green",
        message: "Pari créé avec succès",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 5000,
      });
      setBet(data);
    },
    onError: (error: Error) => {
      buttonIsLoadingDispatch({ type: "create", isLoading: false });
      notifications.update({
        id: BET_CREATION_NOTIFICATION_ID,
        color: "red",
        title: "Une erreur est survenue lors de la création du pari",
        message: error.toString(),
        icon: <IconCircleX size="1rem" />,
        autoClose: 5000,
      });
    },
  });

  const { mutate: betUpdateMutation } = useMutation({
    mutationFn: async () => {
      const idToken = await accountService.generateIDToken();
      const betForm = new BetForm(
        matchId!,
        oddCategory,
        scoresProvided ? homeTeamScore : null,
        scoresProvided ? awayTeamScore : null
      );
      await apiService.updateBet(betForm, { idToken: idToken });
    },
    onMutate: () => {
      buttonIsLoadingDispatch({ type: "update", isLoading: true });
      notifications.show({
        id: BET_UPDATE_NOTIFICATION_ID,
        loading: true,
        message: "Mise à jour du match en cours, veuillez patienter.",
        autoClose: false,
      });
    },
    onSuccess: () => {
      buttonIsLoadingDispatch({ type: "update", isLoading: false });
      notifications.update({
        id: BET_UPDATE_NOTIFICATION_ID,
        color: "green",
        message: "Mise à jour du pari réussie",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 5000,
      });
      const newBet = new Bet(
        bet!.id,
        matchId!,
        bet!.userId,
        oddCategory,
        scoresProvided ? homeTeamScore : null,
        scoresProvided ? awayTeamScore : null
      );
      setBet(newBet);
    },
    onError: (error: Error) => {
      buttonIsLoadingDispatch({ type: "update", isLoading: false });
      notifications.update({
        id: BET_UPDATE_NOTIFICATION_ID,
        color: "red",
        title: "Une erreur est survenue lors de la mise à jour du pari",
        message: error.toString(),
        icon: <IconCircleX size="1rem" />,
        autoClose: 5000,
      });
    },
  });

  const { mutate: betDeleteMutation } = useMutation({
    mutationFn: async () => {
      const idToken = await accountService.generateIDToken();
      await apiService.deleteBet(matchId!, { idToken: idToken });
    },
    onMutate: () => {
      buttonIsLoadingDispatch({ type: "delete", isLoading: true });
      notifications.show({
        id: BET_DELETION_NOTIFICATION_ID,
        loading: true,
        message: "Pari en cours de suppression, veuillez patienter",
        autoClose: false,
      });
    },
    onSuccess: () => {
      buttonIsLoadingDispatch({ type: "delete", isLoading: false });
      notifications.update({
        id: BET_DELETION_NOTIFICATION_ID,
        color: "green",
        message: "Suppression du pari réussie",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 5000,
      });
      setBet(null);
    },
    onError: (error: Error) => {
      buttonIsLoadingDispatch({ type: "delete", isLoading: false });
      notifications.update({
        id: BET_DELETION_NOTIFICATION_ID,
        color: "red",
        title: "Une erreur est survenue lors de la suppression du pari",
        message: error.toString(),
        icon: <IconCircleX size="1rem" />,
        autoClose: 5000,
      });
    },
  });

  let defaultOddCategory: OddCategory;
  let actionButtonComponent: ReactElement;
  if (bet === null) {
    defaultOddCategory = OddCategory.ODD_C1;
    actionButtonComponent = (
      <Center>
        <Button
          onClick={() => betCreateMutation()}
          loading={buttonIsLoading.create}
          disabled={match !== null ? matchLocked(match) : true}
          leftIcon={<IconReceipt />}
        >
          Créer un pronostic
        </Button>
      </Center>
    );
  } else {
    defaultOddCategory = bet.oddCategory;
    actionButtonComponent = (
      <Group position="center">
        <Button
          color="red"
          loading={buttonIsLoading.delete}
          onClick={() => betDeleteMutation()}
          disabled={match !== null ? matchLocked(match) : true}
        >
          Supprimer
        </Button>
        <Button
          loading={buttonIsLoading.update}
          onClick={() => betUpdateMutation()}
          disabled={match !== null ? matchLocked(match) : true}
        >
          Mettre à jour
        </Button>
      </Group>
    );
  }

  const [oddCategory, setOddcategory] =
    useState<OddCategory>(defaultOddCategory);
  const [homeTeamScore, setHomeTeamScore] = useState<number | null>(
    bet?.homeScore ?? null
  );
  const [awayTeamScore, setAwayTeamScore] = useState<number | null>(
    bet?.awayScore ?? null
  );
  const scoresProvided = homeTeamScore !== null && awayTeamScore !== null;

  useEffect(() => {
    setHomeTeamScore(bet?.homeScore ?? null);
    setAwayTeamScore(bet?.awayScore ?? null);
    setOddcategory(bet?.oddCategory ?? defaultOddCategory);
  }, [bet]);  

  useQuery({
    queryKey: ["bet", matchId],
    queryFn: async () => {
      const idToken = await accountService.generateIDToken();
      return await apiService.fetchBet(matchId!, { idToken: idToken });
    },
    onSuccess: (data) => setBet(data),
    onError: () => setBet(null),
    placeholderData: null,
    enabled: matchId !== undefined,
    retry: false,
    // Disable the cache for this query because the returned value may be null even if a value
    // really exists
    staleTime: 0,
  });

  const onlyCategories = filterOddCategories(homeTeamScore, awayTeamScore);
  if (!onlyCategories.includes(defaultOddCategory)) {
    defaultOddCategory = onlyCategories.at(0)!;
  }

  return (
    <div className="match-bet">
      <Title order={4}>Pronostic</Title>
      <Space h="1rem" />
      <Paper withBorder shadow="xs" p="md" radius="lg">
        {match === null ? (
          <Text>Veuillez cliquer sur un match afin de créer un pronostic.</Text>
        ) : (
          <>
            <Group position="center">
              <Image withPlaceholder maw="1rem" src={match.homeTeam.crest} />
              <Text fw={500}>
                {match.homeTeam.name} - {match.awayTeam.name}
              </Text>
              <Image withPlaceholder maw="1rem" src={match.awayTeam.crest} />
            </Group>
            <Space h="1rem" />
            <BetCategorySelect
              defaultCategory={defaultOddCategory}
              onChange={setOddcategory}
              only={onlyCategories}
            />
            <Text>{humanizeOddCategory(oddCategory)}</Text>
            <Space h="1rem" />
            <Group grow>
              <NumberInput
                value={homeTeamScore !== null ? homeTeamScore : ""}
                onChange={(value) =>
                  setHomeTeamScore(value !== "" ? value : null)
                }
                label={`${match.homeTeam.name}`}
                placeholder="Entrez le score"
                min={0}
                hideControls
              />
              <NumberInput
                value={awayTeamScore !== null ? awayTeamScore : ""}
                onChange={(value) =>
                  setAwayTeamScore(value !== "" ? value : null)
                }
                label={`${match.awayTeam.name}`}
                placeholder="Entrez le score"
                min={0}
                hideControls
              />
            </Group>
            <BetScore />
            <Space h="1rem" />
            {actionButtonComponent}
          </>
        )}
      </Paper>
    </div>
  );
}

export default BetManagement;
export { type BetManagementProps };
