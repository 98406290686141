import APIModel from "./base";
import SeasonAttrs from "../../attrs/api/models/season";
import { SeasonFilterAttrs } from "../../attrs/api/models/season";
import { EncodedAPIModel } from "./encoding";

class Season implements APIModel {
  id: number;
  startAt: Date;
  endAt: Date;
  leagueId: number;

  constructor(id: number, startAt: Date, endAt: Date, leagueId: number) {
    this.id = id;
    this.startAt = startAt;
    this.endAt = endAt;
    this.leagueId = leagueId;
  }

  get name(): string {
    let startAtYear = this.startAt.getFullYear();
    let endAtYear = this.endAt.getFullYear();
    return `${startAtYear}-${endAtYear}`;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[SeasonAttrs.ID] = this.id;
    content[SeasonAttrs.START_AT] = this.startAt.toISOString();
    content[SeasonAttrs.END_AT] = this.endAt.toISOString();
    content[SeasonAttrs.LEAGUE_ID] = this.leagueId;
    return JSON.stringify(content);
  }

  static decode(content: string): Season {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    let startAtRaw = decodedContent[SeasonAttrs.START_AT];
    let endAtRaw = decodedContent[SeasonAttrs.END_AT];
    return new Season(
      decodedContent[SeasonAttrs.ID],
      new Date(startAtRaw),
      new Date(endAtRaw),
      decodedContent[SeasonAttrs.LEAGUE_ID]
    );
  }
}

class SeasonFilter implements APIModel {
  nbSeasons: number;
  offset: number;
  leagueIds: number[] | null;

  constructor(nbSeasons: number, offset: number, leagueIds: number[] | null) {
    this.nbSeasons = nbSeasons;
    this.offset = offset;
    this.leagueIds = leagueIds;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[SeasonFilterAttrs.NB_SEASONS] = this.nbSeasons;
    content[SeasonFilterAttrs.OFFSET] = this.offset;
    if (this.leagueIds !== null && this.leagueIds.length > 0) {
      content[SeasonFilterAttrs.LEAGUE_IDS] = this.leagueIds.join(",");
    }
    return JSON.stringify(content);
  }

  static decode(content: string): SeasonFilter {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new SeasonFilter(
      decodedContent[SeasonFilterAttrs.NB_SEASONS],
      decodedContent[SeasonFilterAttrs.OFFSET],
      decodedContent[SeasonFilterAttrs.LEAGUE_IDS]
    );
  }
}

export default Season;
export { SeasonFilter };
