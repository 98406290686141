/**
 * An enumeration of common HTTP headers.
 */
enum Header {
  AUTHORIZATION = "Authorization",
  CONTENT_TYPE = "Content-Type",
}

type HeaderType = Header | string;
type HeaderValue = string;
interface Headers {
  [key: HeaderType]: HeaderValue;
}

/**
 * Prefix to remove from a bearer token to extract its value.
 */
const _BEARER_TOKEN_PREFIX: string = "Bearer ";

/**
 * Extracts a bearer token from HTTP headers.
 *
 * Bearer token is searched in the `Authorization` header and shall respect the bearer format.
 *
 * @param header HTTP header.
 * @returns content of the bearer token, if present, otherwise `null`.
 */
function getBearerTokenFromHeaders(headers: Headers): string | null {
  let authorizationHeader: HeaderValue | undefined =
    headers[Header.AUTHORIZATION];
  if (authorizationHeader === undefined) {
    return null;
  }

  if (!authorizationHeader.startsWith(_BEARER_TOKEN_PREFIX)) return null;
  return authorizationHeader.replace(_BEARER_TOKEN_PREFIX, "");
}

/**
 * Sets a bearer token in HTTP headers.
 *
 * Bearer token is inserted in the `Authorization` header.
 *
 * @param headers HTTP headers.
 * @param token encoded token.
 */
function setBearerTokenInHeaders(headers: Headers, token: string): void {
  let bearerToken = _BEARER_TOKEN_PREFIX + token;
  headers[Header.AUTHORIZATION] = bearerToken;
}

/**
 * Sets new HTTP header.
 *
 * @param headers HTTP headers.
 * @param headerType type of HTTP header.
 * @param headerValue value to insert.
 */
function setHeader(
  headers: Headers,
  headerType: HeaderType,
  headerValue: HeaderValue
): void {
  headers[headerType] = headerValue;
}

export {
  Header,
  type Headers,
  getBearerTokenFromHeaders,
  setBearerTokenInHeaders,
  setHeader,
};
