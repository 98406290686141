import { Box, Button, Checkbox, Grid, Stepper, TextInput } from "@mantine/core";
import {
  useForm,
  isNotEmpty,
  isEmail,
  hasLength,
  matchesField,
} from "@mantine/form";
import { DatesProvider, DateInput } from "@mantine/dates";
import { locale } from "dayjs";

import { checkPasswordStrength } from "../../security";
import PasswordInput from "../UI/PasswordInput";
import { useState } from "react";
import { checkAge, getBirthDate } from "../../utils/age";

interface UserFormFields {
  name: string;
  surname: string;
  birthDate: Date;
  email: string;
  emailConfirmation: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  termsOfUse: boolean;
}

const LEGAL_AGE: number = 18;

/**
 * Initializes each user form field to its default value.
 *
 * @returns a default value for each field.
 */
function initializeFields(): UserFormFields {
  return {
    name: "",
    surname: "",
    birthDate: getBirthDate(LEGAL_AGE),
    email: "",
    emailConfirmation: "",
    username: "",
    password: "",
    passwordConfirmation: "",
    termsOfUse: false,
  };
}

function validateFieldsAtStep(step: number, fields: UserFormFields) {
  if (step === 0) {
    return {
      name: isNotEmpty("Le prénom ne peut être vide")(fields.name),
      surname: isNotEmpty("Le nom ne peut être vide")(fields.surname),
      birthDate: checkAge(fields.birthDate, LEGAL_AGE)
        ? null
        : "Vous devez être majeur afin d'accéder aux services proposés",
    };
  }
  if (step === 1) {
    return {
      username: hasLength(
        { min: 8, max: 32 },
        "Le pseudo doit comprendre entre 8 et 32 caractères"
      )(fields.username),
      email: isEmail("Mail invalide")(fields.email),
      emailConfirmation: matchesField(
        "email",
        "Les mails ne correspondent pas"
      )(fields.emailConfirmation, { email: fields.email }),
    };
  }
  if (step === 2) {
    return {
      password: checkPasswordStrength(fields.password)
        ? null
        : "Mot de passe trop faible",
      passwordConfirmation: matchesField(
        "password",
        "Les mots de passe ne correspondent pas"
      )(fields.passwordConfirmation, { password: fields.password }),
    };
  }

  return {
    termsOfUse: fields.termsOfUse
      ? null
      : "Les conditions d'utilisation doivent être lues et acceptées",
  };
}

interface UserFormProps {
  onSubmit(fields: UserFormFields): void;
  onCancel(): void;
}

function UserForm({ onSubmit, onCancel }: UserFormProps) {
  const [activeStep, setActiveStep] = useState(0);

  const form = useForm<UserFormFields>({
    initialValues: initializeFields(),
    validate: (values) => validateFieldsAtStep(activeStep, values),
    validateInputOnBlur: true,
  });

  const nextStep = () =>
    setActiveStep((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  return (
    <div className="user-form">
      <DatesProvider settings={{ locale: locale() }}>
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <Stepper active={activeStep} breakpoint="sm">
            <Stepper.Step
              label="Première étape"
              description="Informations personnelles"
            >
              <Box maw={350} mx="auto">
                <TextInput
                  withAsterisk
                  label="Prénom"
                  placeholder="Seugon"
                  {...form.getInputProps("name")}
                />
                <TextInput
                  withAsterisk
                  label="Nom"
                  placeholder="Potopavarre"
                  {...form.getInputProps("surname")}
                />
                <DateInput
                  withAsterisk
                  label="Date de naissance"
                  placeholder="Date de naissance"
                  maxDate={getBirthDate(18)}
                  {...form.getInputProps("birthDate")}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step
              label="Deuxième étape"
              description="Paramètres du profil"
            >
              <Box maw={350} mx="auto">
                <TextInput
                  withAsterisk
                  label="Pseudo"
                  placeholder="xXSup3rG4m3Xx"
                  {...form.getInputProps("username")}
                />
                <TextInput
                  withAsterisk
                  label="Mail"
                  placeholder="votre@mail.com"
                  {...form.getInputProps("email")}
                />
                <TextInput
                  withAsterisk
                  label="Confirmation du mail"
                  placeholder="votre@mail.com"
                  {...form.getInputProps("emailConfirmation")}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step label="Troisème étape" description="Mot de passe">
              <Box maw={350} mx="auto">
                <PasswordInput
                  passwordInputProps={form.getInputProps("password")}
                  confirmationPasswordInputProps={form.getInputProps(
                    "passwordConfirmation"
                  )}
                />
              </Box>
            </Stepper.Step>
            <Stepper.Step
              label="Dernière étape"
              description="Conditions d'utilisation"
            >
              <Box maw={350} mx="auto">
                <Checkbox
                  mt="md"
                  label="En cliquant sur ce bouton, vous acceptez nos conditions d'utilisation"
                  {...form.getInputProps("termsOfUse", { type: "checkbox" })}
                />
              </Box>
            </Stepper.Step>
          </Stepper>

          <Grid mt="xl" justify="space-between" align="center">
            <Grid.Col span={3}>
              <Button
                variant="default"
                onClick={prevStep}
                disabled={activeStep === 0}
              >
                Retour
              </Button>
            </Grid.Col>

            <Grid.Col span={3}>
              <Button color="red" onClick={onCancel}>
                Annuler
              </Button>
            </Grid.Col>

            {activeStep < 3 && (
              <Grid.Col span={3}>
                <Button onClick={nextStep}>Étape suivante</Button>
              </Grid.Col>
            )}
            {activeStep === 3 && (
              <Grid.Col span={3}>
                <Button type="submit">Créer mon compte</Button>
              </Grid.Col>
            )}
          </Grid>
        </form>
      </DatesProvider>
    </div>
  );
}

export default UserForm;
export { type UserFormProps, type UserFormFields };
