import {
  ActionIcon,
  Avatar,
  Group,
  Menu,
  MenuItemProps,
  Text,
} from "@mantine/core";
import {
  IconSettings,
  IconUserPlus,
  IconLogin,
  IconLogout,
  IconUserCircle,
  IconChevronRight,
} from "@tabler/icons-react";

import { ReactElement } from "react";
import { Account } from "../../services/account";

interface UserMenuProps {
  onCreateAccount(event?: React.MouseEvent): void;
  onLogIn(event?: React.MouseEvent): void;
  onSettings(event?: React.MouseEvent): void;
  onLogOut(): void;
  account: Account | null;
  [key: string]: any;
}

function UserMenu({
  onCreateAccount,
  onLogIn,
  onSettings,
  onLogOut,
  account,
  ...rest
}: UserMenuProps) {
  let menuItems: ReactElement<MenuItemProps>[];
  if (account !== null) {
    menuItems = [
      <Menu.Item
        rightSection={<IconChevronRight size="1rem" stroke={1.5} />}
        key="user-menu-name"
      >
        <Group>
          <Avatar radius="xl">
            {account.name.at(0)}
            {account.surname.at(0)}
          </Avatar>
          <div>
            <Text weight={500}>{}</Text>
            <Text size="0.8rem" color="dimmed">
              {account.email}
            </Text>
          </div>
        </Group>
      </Menu.Item>,
      <Menu.Item
        icon={<IconLogout size="1rem" stroke={1.5} />}
        onClick={onLogOut}
        key="user-menu-logout"
      >
        Se déconnecter
      </Menu.Item>,
    ];
  } else {
    menuItems = [
      <Menu.Item
        icon={<IconUserPlus size="1.25rem" stroke={1.5} />}
        onClick={onCreateAccount}
        key="user-menu-create-account"
      >
        Créer un compte
      </Menu.Item>,
      <Menu.Item
        icon={<IconLogin size="1.25rem" stroke={1.5} />}
        onClick={onLogIn}
        key="user-menu-login"
      >
        Se connecter
      </Menu.Item>,
    ];
  }

  return (
    <div className="user-menu" {...rest}>
      <Menu withArrow shadow="md" width="17rem">
        <Menu.Target>
          <ActionIcon radius="xl">
            <IconUserCircle size="1.5rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Profil et paramètres</Menu.Label>
          {menuItems}
          <Menu.Divider />
          <Menu.Item
            icon={<IconSettings size="1rem" stroke={1.5} />}
            onClick={onSettings}
            key="user-menu-settings"
          >
            Paramètres
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

export default UserMenu;
export { type UserMenuProps };
