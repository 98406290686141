import { Paper, Text } from "@mantine/core";

interface RankingScoreProps {
  score: number;
}

function RankingScore({ score }: RankingScoreProps) {
  return (
    <div className="ranking-score">
      <Paper shadow="xs" radius="md" p="xs">
        <Text color="dimmed" size="sm" weight={550}>
          Score
        </Text>
        <Text weight={700} size="xl">
          {score}
        </Text>
      </Paper>
    </div>
  );
}

export default RankingScore;
export { type RankingScoreProps as RankingPointsProps };
