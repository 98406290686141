class MatchAttrs {
  static readonly ID: string = "id";
  static readonly SEASON: string = "season";
  static readonly LEAGUE: string = "league";
  static readonly HOME_TEAM: string = "homeTeam";
  static readonly AWAY_TEAM: string = "awayTeam";
  static readonly DAY: string = "day";
  static readonly STATUS: string = "status";
  static readonly RESULT: string = "result";
}

class MatchStatusAttrs {
  static readonly ID: string = "id";
  static readonly MATCH_ID: string = "matchId";
  static readonly START_AT: string = "startAt";
  static readonly STATUS: string = "status";
}

class MatchResultAttrs {
  static readonly ID: string = "id";
  static readonly MATCH_ID: string = "matchId";
  static readonly HOME_SCORE: string = "homeScore";
  static readonly AWAY_SCORE: string = "awayScore";
}

class MatchFilterAttrs {
  static readonly NB_MATCHES: string = "nbMatches";
  static readonly OFFSET: string = "offset";
  static readonly LEAGUE_ID: string = "leagueId";
  static readonly SEASON_ID: string = "seasonId";
  static readonly START_BEFORE: string = "startBefore";
  static readonly START_AFTER: string = "startAfter";
}

export default MatchAttrs;
export { MatchStatusAttrs, MatchResultAttrs, MatchFilterAttrs };
