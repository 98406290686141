import {
  Group,
  Paper,
  RingProgress,
  Space,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  RankingScore,
  RankingScoreStatus,
} from "../../api/models/games/ranking";
import { useState } from "react";
import { IconTargetArrow } from "@tabler/icons-react";

interface RankingProportionsProps {
  size?: number;
  thickness?: number;
  scores: RankingScore[];
}

function RankingProportions({
  size,
  thickness,
  scores,
}: RankingProportionsProps) {
  let nbNotPlayedScores = scores.filter(
    (score) => score.status === RankingScoreStatus.NOT_PLAYED
  ).length;
  let nbFailureScores = scores.filter(
    (score) => score.status === RankingScoreStatus.FAILURE
  ).length;
  let nbSuccessScores = scores.filter(
    (score) => score.status === RankingScoreStatus.SUCCESS
  ).length;

  let nbScores = scores.length;

  let percentNotPlayed = (nbNotPlayedScores * 100) / nbScores;
  let percentFailures = (nbFailureScores * 100) / nbScores;
  let percentSuccesses = (nbSuccessScores * 100) / nbScores;

  const [nbScoreText, setNbScoreText] = useState<string>(
    nbSuccessScores.toString()
  );
  const [nbScoreColor, setNbScoreColor] = useState<string>("green");
  const [scoreTypeText, setScoreTypeText] = useState<string>("Successes");

  let ringSections = [
    {
      value: percentNotPlayed,
      color: "orange",
      onMouseEnter: () => {
        setNbScoreText(nbNotPlayedScores.toString());
        setNbScoreColor("orange");
        setScoreTypeText("Not played");
      },
    },
    {
      value: percentFailures,
      color: "red",
      onMouseEnter: () => {
        setNbScoreText(nbFailureScores.toString());
        setNbScoreColor("red");
        setScoreTypeText("Failures");
      },
    },
    {
      value: percentSuccesses,
      color: "green",
      onMouseEnter: () => {
        setNbScoreText(nbSuccessScores.toString());
        setNbScoreColor("green");
        setScoreTypeText("Successes");
      },
    },
  ];

  return (
    <div className="ranking-proportions">
      <Paper shadow="xs" radius="md" p="xs">
        <Group position="apart">
          <Text color="dimmed" size="sm" weight={550}>
            Pronostics
          </Text>
          <ThemeIcon variant="light" color="gray" size="md" radius="md">
            <IconTargetArrow color="black" />
          </ThemeIcon>
        </Group>
        <Space h="1rem" />
        <Group>
          <RingProgress
            size={size}
            thickness={thickness}
            sections={ringSections}
            label={
              <Text
                weight={600}
                align="center"
                size="1.5rem"
                color={nbScoreColor}
              >
                {nbScoreText}
              </Text>
            }
          />
          <div>
            <Text weight={700} size="xl">
              {scoreTypeText}
            </Text>
          </div>
        </Group>
      </Paper>
    </div>
  );
}

export default RankingProportions;
export { type RankingProportionsProps as RankingProportionProps };
