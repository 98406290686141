import { useEffect, useState } from "react";
import { Center, Grid, Loader, Space, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

import AccountService from "../../../services/account";
import { APIService } from "../../../services/api";
import League from "../../../api/models/league";
import Season, { SeasonFilter } from "../../../api/models/season";
import { Ranking, RankingFilter } from "../../../api/models/games/ranking";
import LeagueSelect from "../LeagueSelect";
import SeasonSelect from "../SeasonSelect";
import RankingTable from "../RankingTable";

interface RankingPageProps {
  accountService: AccountService;
  apiService: APIService;
}

const FETCH_LEAGUES_QUERY_ID: string = "leagues";
const FETCH_SEASONS_QUERY_ID: string = "seasons";
const FETCH_RANKING_QUERY_ID: string = "ranking";
const FETCH_RANKINGS_QUERY_ID: string = "rankings";

const DEFAULT_RANKING_TABLE_SIZE: number = 20;

function RankingPage({ accountService, apiService }: RankingPageProps) {
  const [selectedLeague, setSelectedLeague] = useState<League | null>(null);
  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);

  const { data: leagues } = useQuery({
    queryKey: [FETCH_LEAGUES_QUERY_ID],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchLeagues({ idToken: idToken });
    },
    placeholderData: [],
  });

  const seasonQueryEnabled = selectedLeague !== null;
  const seasonFilter = new SeasonFilter(
    10,
    0,
    seasonQueryEnabled ? [selectedLeague.id] : null
  );
  const { data: seasons } = useQuery({
    queryKey: [FETCH_SEASONS_QUERY_ID, seasonFilter.encode()],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchSeasons(seasonFilter, { idToken: idToken });
    },
    placeholderData: [],
    enabled: seasonQueryEnabled,
  });

  useEffect(() => {
    if (selectedLeague === null) {
      setSelectedLeague(leagues!.at(0) ?? null);
    }
  }, [leagues]);

  useEffect(() => {
    if (selectedSeason === null) {
      setSelectedSeason(seasons!.at(0) ?? null);
    }
  }, [seasons]);

  const leagueChangeHandler = (leagueName: string) => {
    const selectedLeague: League | undefined = leagues!.find(
      (league) => leagueName === league.name
    );
    setSelectedLeague(selectedLeague ?? null);
  };

  // Currently, ranking table size cannot be modified by the user
  const [rankingTableSize] = useState<number>(DEFAULT_RANKING_TABLE_SIZE);
  const [rankingTablePage, setRankingTablePage] = useState<number>(1);

  const [userRanking, setUserRanking] = useState<Ranking | null>(null);
  const [opponentRankings, setOpponentRankings] = useState<Ranking[]>([]);

  const rankingQueryEnabled =
    selectedLeague !== null && selectedSeason !== null;
  const rankingFilter = new RankingFilter(
    rankingTableSize,
    // Pagination number starts at 1, substract 1 to the page number to correctly slice the array of
    // opponent rankings
    (rankingTablePage - 1) * rankingTableSize,
    selectedLeague !== null ? [selectedLeague.id] : null,
    selectedSeason !== null ? [selectedSeason.id] : null
  );
  const {
    isLoading: userRankingQueryIsLoading,
    isError: userRankinQueryIsError,
  } = useQuery({
    queryKey: [FETCH_RANKING_QUERY_ID, rankingFilter.encode()],
    queryFn: async () => {
      let account = await accountService.getCurrentAccount();
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchRanking(account.uid, rankingFilter, {
        idToken: idToken,
      });
    },
    onSuccess: (data) => setUserRanking(data),
    onError: () => setUserRanking(null),
    enabled: rankingQueryEnabled,
    retry: false,
  });

  const nbPlayers = userRanking?.nbPlayers ?? 0;

  const {
    isLoading: opponentRankingsQueryIsLoading,
    isError: opponentRankingsQueryIsError,
  } = useQuery({
    queryKey: [FETCH_RANKINGS_QUERY_ID, rankingFilter.encode()],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchRankings(rankingFilter, {
        idToken: idToken,
      });
    },
    onSuccess: (data) => setOpponentRankings(data),
    onError: () => setOpponentRankings([]),
    enabled: rankingQueryEnabled,
    retry: false,
  });

  // Compute the number of rankings to fetch with the table size and total number of players
  const nbPages = Math.ceil(nbPlayers / rankingTableSize);

  const onTablePageChange = (pageNumber: number) => {
    setRankingTablePage(pageNumber);
  };

  return (
    <div className="ranking-page">
      <Grid>
        <Grid.Col span={8}>
          <Title>Classement</Title>
          <Text color="dimmed" size="md" weight={500}>
            Classement général
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <LeagueSelect
            leagues={leagues!.map((l) => l.name)}
            defaultLeague={leagues?.at(0)?.name}
            onChange={leagueChangeHandler}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <SeasonSelect
            seasons={seasons!}
            onChange={setSelectedSeason}
            disabled={seasons!.length === 0}
          />
        </Grid.Col>
      </Grid>
      <Space h="2rem" />
      {userRankingQueryIsLoading || opponentRankingsQueryIsLoading ? (
        <Center>
          <Loader variant="dots" />
        </Center>
      ) : userRanking === null ||
        opponentRankings.length === 0 ||
        userRankinQueryIsError ||
        opponentRankingsQueryIsError ? (
        <Text>Aucune donnée à afficher.</Text>
      ) : (
        <RankingTable
          accountService={accountService}
          apiService={apiService}
          userRanking={userRanking!}
          opponentRankings={opponentRankings!}
          pageTotal={nbPages}
          onPageChange={onTablePageChange}
        />
      )}
    </div>
  );
}

export default RankingPage;
export { type RankingPageProps };
