import { Select } from "@mantine/core";

import Season from "../../api/models/season";
import { IconCalendar } from "@tabler/icons-react";

interface SeasonSelectProps {
  seasons: Season[];
  disabled?: boolean;
  onChange(season: Season | null): void;
}

function SeasonSelect({ seasons, disabled, onChange }: SeasonSelectProps) {
  const onChangeHandler = (selectedSeasonName: string | null) => {
    if (selectedSeasonName === null) {
      return onChange(null);
    }
    const selectedSeason: Season | undefined = seasons.find(
      (season) => selectedSeasonName === season.name
    );
    onChange(selectedSeason!);
  };

  const seasonsData: string[] = seasons.map((s) => s.name);

  const defaultSeasonName = seasonsData.length > 0 ? seasonsData[0] : null;
  // Call handler to provide the default value when seasons are not empty
  onChangeHandler(defaultSeasonName);

  return (
    <div className="season-select">
      <Select
        searchable
        data={seasonsData}
        defaultValue={defaultSeasonName}
        placeholder="Sélectionner une saison"
        onChange={onChangeHandler}
        disabled={disabled}
        nothingFound="Aucune saison trouvée"
        radius="md"
        icon={<IconCalendar size="1rem" />}
        hoverOnSearchChange
      />
    </div>
  );
}

export default SeasonSelect;
export { type SeasonSelectProps };
