import {
  BetAttrs,
  BetFilterAttrs,
  BetFormAttrs,
  BetResultAttrs,
} from "../../attrs/api/models/bet";
import APIModel from "./base";
import { EncodedAPIModel } from "./encoding";

enum OddCategory {
  // Home team wins
  ODD_C1,
  // Draw
  ODD_CN,
  // Away team wins
  ODD_C2,
  // Home team wins or draw
  ODD_1N,
  // Away team wins or draw
  ODD_N2,
  // Home team or away team wins
  ODD_12,
}

function getOddCategoryName(oddCategory: OddCategory): string {
  switch (oddCategory) {
    case OddCategory.ODD_C1:
      return "1";
    case OddCategory.ODD_CN:
      return "N";
    case OddCategory.ODD_C2:
      return "2";
    case OddCategory.ODD_1N:
      return "1N";
    case OddCategory.ODD_N2:
      return "N2";
    case OddCategory.ODD_12:
      return "12";
    default:
      throw new Error(`Unknwon odd category '${oddCategory}'`);
  }
}

function humanizeOddCategory(oddCategory: OddCategory): string {
  switch (oddCategory) {
    case OddCategory.ODD_C1:
      return "Domicile gagne";
    case OddCategory.ODD_CN:
      return "Égalité";
    case OddCategory.ODD_C2:
      return "Extérieur gagne";
    case OddCategory.ODD_1N:
      return "Domicile gagne ou égalité";
    case OddCategory.ODD_N2:
      return "Extérieur gagne ou égalité";
    case OddCategory.ODD_12:
      return "Domicile ou extérieur gagne";
    default:
      throw new Error(`Unknwon odd category '${oddCategory}'`);
  }
}

class BetForm implements APIModel {
  matchId: number;
  oddCategory: OddCategory;
  homeScore: number | null;
  awayScore: number | null;

  constructor(
    matchId: number,
    oddCategory: OddCategory,
    homeScore: number | null,
    awayScore: number | null
  ) {
    this.matchId = matchId;
    this.oddCategory = oddCategory;
    this.homeScore = homeScore;
    this.awayScore = awayScore;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[BetFormAttrs.MATCH_ID] = this.matchId;
    content[BetFormAttrs.ODD_CATEGORY] = this.oddCategory.toString();
    content[BetFormAttrs.HOME_SCORE] = this.homeScore;
    content[BetFormAttrs.AWAY_SCORE] = this.awayScore;
    return JSON.stringify(content);
  }

  static decode(content: string): BetForm {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new BetForm(
      decodedContent[BetFormAttrs.MATCH_ID],
      decodedContent[BetFormAttrs.ODD_CATEGORY],
      decodedContent[BetFormAttrs.HOME_SCORE],
      decodedContent[BetFormAttrs.AWAY_SCORE]
    );
  }
}

class Bet implements APIModel {
  id: number;
  matchId: number;
  userId: number;
  oddCategory: OddCategory;
  homeScore: number | null;
  awayScore: number | null;

  constructor(
    id: number,
    matchId: number,
    userId: number,
    oddCategory: OddCategory,
    homeScore: number | null,
    awayScore: number | null
  ) {
    this.id = id;
    this.matchId = matchId;
    this.userId = userId;
    this.oddCategory = oddCategory;
    this.homeScore = homeScore;
    this.awayScore = awayScore;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[BetAttrs.ID] = this.id;
    content[BetAttrs.MATCH_ID] = this.matchId;
    content[BetAttrs.USER_ID] = this.userId;
    content[BetAttrs.ODD_CATEGORY] = this.oddCategory;
    content[BetAttrs.HOME_SCORE] = this.homeScore;
    content[BetAttrs.AWAY_SCORE] = this.awayScore;
    return JSON.stringify(content);
  }

  static decode(content: string): Bet {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new Bet(
      decodedContent[BetAttrs.ID],
      decodedContent[BetAttrs.MATCH_ID],
      decodedContent[BetAttrs.USER_ID],
      parseInt(decodedContent[BetAttrs.ODD_CATEGORY]),
      decodedContent[BetAttrs.HOME_SCORE],
      decodedContent[BetAttrs.AWAY_SCORE]
    );
  }
}

class BetFilter implements APIModel {
  matchIds: number[] | null;
  nbBets: number;
  offset: number;
  seasonId: number | null;
  leagueId: number | null;
  over: boolean | null;

  constructor(
    matchIds: number[] | null,
    nbBets: number,
    offset: number,
    seasonId: number | null,
    leagueId: number | null,
    over: boolean | null
  ) {
    this.matchIds = matchIds;
    this.nbBets = nbBets;
    this.offset = offset;
    this.seasonId = seasonId;
    this.leagueId = leagueId;
    this.over = over;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    if (this.matchIds !== null && this.matchIds.length > 0) {
      content[BetFilterAttrs.MATCH_IDS] = this.matchIds.join(",");
    }
    content[BetFilterAttrs.NB_BETS] = this.nbBets;
    content[BetFilterAttrs.OFFSET] = this.offset;
    if (this.seasonId !== null) {
      content[BetFilterAttrs.SEASON_ID] = this.seasonId;
    }
    if (this.leagueId !== null) {
      content[BetFilterAttrs.LEAGUE_ID] = this.leagueId;
    }
    if (this.over !== null) {
      content[BetFilterAttrs.OVER] = this.over;
    }
    return JSON.stringify(content);
  }

  static decode(content: string): BetFilter {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new BetFilter(
      decodedContent[BetFilterAttrs.MATCH_IDS],
      decodedContent[BetFilterAttrs.NB_BETS],
      decodedContent[BetFilterAttrs.OFFSET],
      decodedContent[BetFilterAttrs.SEASON_ID],
      decodedContent[BetFilterAttrs.LEAGUE_ID],
      decodedContent[BetFilterAttrs.OVER]
    );
  }
}

class BetResult implements APIModel {
  id: number;
  betId: number;
  isOddSuccess: boolean;
  isScoreSuccess: boolean;

  constructor(
    id: number,
    betId: number,
    isOddSuccess: boolean,
    isScoreSuccess: boolean
  ) {
    this.id = id;
    this.betId = betId;
    this.isOddSuccess = isOddSuccess;
    this.isScoreSuccess = isScoreSuccess;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[BetResultAttrs.ID] = this.id;
    content[BetResultAttrs.BET_ID] = this.betId;
    content[BetResultAttrs.IS_ODD_SUCCESS] = this.isOddSuccess;
    content[BetResultAttrs.IS_SCORE_SUCCESS] = this.isScoreSuccess;
    return JSON.stringify(content);
  }

  static decode(content: string): BetResult {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new BetResult(
      decodedContent[BetResultAttrs.ID],
      decodedContent[BetResultAttrs.BET_ID],
      decodedContent[BetResultAttrs.IS_ODD_SUCCESS],
      decodedContent[BetResultAttrs.IS_SCORE_SUCCESS]
    );
  }
}

export default Bet;
export {
  BetFilter,
  BetForm,
  BetResult,
  OddCategory,
  getOddCategoryName,
  humanizeOddCategory,
};
