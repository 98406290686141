import {
  Center,
  Group,
  Image,
  Loader,
  Paper,
  Space,
  Table,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCalendarStats } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";

import {
  RankingScore,
  humanizeRankingStatus,
} from "../../api/models/games/ranking";
import { APIService } from "../../services/api";
import AccountService from "../../services/account";
import { humanizeDate } from "../../utils/date";
import Bet, { BetFilter, getOddCategoryName } from "../../api/models/bet";
import Match from "../../api/models/match";
import { useMatches } from "../../hooks/api";
import InfinitePagination from "../UI/InfinitePagination";

interface RankingScoreRowProps {
  match: Match;
  score: RankingScore;
  bet: Bet | null;
}

function RankingScoreRow({ match, score, bet }: RankingScoreRowProps) {
  const homeTeam = match.homeTeam;
  const awayTeam = match.awayTeam;
  const result = match.result;
  const homeTeamScore = result!.homeScore.toString();
  const awayTeamScore = result!.awayScore.toString();

  let formattedStartAt = humanizeDate(match.status.startAt);

  let betText: JSX.Element;
  if (bet !== null) {
    const betHomeScore =
      bet.homeScore !== null ? bet.homeScore.toString() : "-";
    const betAwayScore =
      bet.awayScore !== null ? bet.awayScore.toString() : "-";
    let betScore: string = `${betHomeScore} : ${betAwayScore}`;
    betText = (
      <Text>
        {`${betScore}`} &nbsp; {getOddCategoryName(bet.oddCategory)}
      </Text>
    );
  } else {
    betText = <Text>Aucun pronostic</Text>;
  }

  return (
    <tr key={match.id} className="ranking-score-table__row">
      <td>
        <Text>{formattedStartAt}</Text>
      </td>
      <td style={{ textAlign: "right" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Text fw={600}>{homeTeam.name}</Text>
          <Image withPlaceholder maw="1rem" src={homeTeam.crest} />
        </div>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text fw={600}>{`${homeTeamScore} : ${awayTeamScore}`}</Text>
      </td>
      <td style={{ textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Image
            withPlaceholder
            height="1rem"
            width="1rem"
            maw="1rem"
            src={awayTeam.crest}
          />
          <Text fw={600}>{awayTeam.name}</Text>
        </div>
      </td>
      <td style={{ textAlign: "center" }}>{betText}</td>
      <td style={{ textAlign: "center" }}>
        <Text>{humanizeRankingStatus(score.status)}</Text>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text>{score.score}</Text>
      </td>
    </tr>
  );
}

function RankingScoreHeader() {
  return (
    <thead className="ranking-score-table__header">
      <tr>
        <th>
          <Text color="dimmed" size="md" weight={500}>
            Date
          </Text>
        </th>
        <th style={{ textAlign: "right" }}></th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Score
          </Text>
        </th>
        <th style={{ textAlign: "left" }}></th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Pronostic
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Résultat
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Score
          </Text>
        </th>
      </tr>
    </thead>
  );
}

interface RankingScoreTableProps {
  accountService: AccountService;
  apiService: APIService;
  scores: RankingScore[];
  defaultPage?: number;
  onPageChange(page: number): void;
}

function RankingScoreTable({
  accountService,
  apiService,
  scores,
  defaultPage = 1,
  onPageChange,
}: RankingScoreTableProps) {
  const matchIds = scores.map((s) => s.matchId);
  const matches = useMatches(matchIds, accountService, apiService);

  const firstMatch = matches.data.at(0);
  const betFilter = new BetFilter(
    matchIds,
    matches.data.length,
    0,
    firstMatch === undefined ? null : firstMatch.season.id,
    firstMatch === undefined ? null : firstMatch.league.id,
    null
  );

  const betsQueryEnabled = matches.status === "success";
  const { data: bets } = useQuery({
    queryKey: ["bets", betFilter.encode()],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchBets(betFilter, {
        idToken: idToken,
      });
    },
    placeholderData: [],
    enabled: betsQueryEnabled,
  });

  const rows: Array<any> = [];
  matches.data.forEach((match, index) => {
    const homeTeam = match.homeTeam;
    const awayTeam = match.awayTeam;
    if (!homeTeam || !awayTeam) {
      return;
    }

    const bet = bets?.filter((bet) => bet.matchId === match.id)[0] ?? null;

    rows.push(
      <RankingScoreRow
        match={match}
        score={scores[index]}
        bet={bet}
        key={match.id}
      />
    );
  });

  return (
    <div className="ranking-score-table">
      <Paper shadow="xs" radius="md" p="xs">
        <div style={{ height: "100%" }}>
          <Group position="apart">
            <Title order={4}>Aperçu du score</Title>
            <ThemeIcon variant="light" color="gray" size="md" radius="md">
              <IconCalendarStats color="black" />
            </ThemeIcon>
          </Group>
        </div>
        {rows.length === 0 ? (
          <Center>
            <Loader variant="dots" />
          </Center>
        ) : (
          <>
            <Space h="1rem" />
            <Table>
              <RankingScoreHeader />
              <tbody>{rows}</tbody>
            </Table>
            <Space h="0.5rem" />
            <Center>
              <InfinitePagination
                onPageChange={onPageChange}
                defaultPage={defaultPage}
              />
            </Center>
          </>
        )}
      </Paper>
    </div>
  );
}

export default RankingScoreTable;
export { type RankingScoreTableProps };
