class RankingScoreAttrs {
  static readonly UID: string = "uid";
  static readonly MATCH_ID: string = "matchId";
  static readonly SCORE: string = "score";
  static readonly STATUS: string = "status";
}

class RankingAttrs {
  static readonly UID: string = "uid";
  static readonly SCORE: string = "score";
  static readonly POSITION: string = "position";
  static readonly NB_PLAYERS: string = "nbPlayers";
}

class RankingFilterAttrs {
  static readonly LIMIT: string = "limit";
  static readonly OFFSET: string = "offset";
  static readonly LEAGUE_IDS: string = "leagueIds";
  static readonly SEASON_IDS: string = "seasonIds";
}

export { RankingAttrs, RankingFilterAttrs, RankingScoreAttrs };
