import axios from "axios";
import { Headers, Header, setHeader } from "./header";
import qs from "qs";

/**
 * Abstract HTTP response.
 */
interface HTTPResponse {
  data: string;
  status: number;
  statusText: string;
  headers: Map<string, string>;
  config: Map<string, any>;
  request?: any;
}

/**
 * A client to interact with HTTP resources.
 */
class HTTPClient {
  private client;

  constructor(
    baseURL: string,
    options: { headers?: Headers; timeout?: number } = {}
  ) {
    this.client = axios.create({
      baseURL: baseURL,
      headers: options.headers,
      timeout: options.timeout,
      transformResponse: (response) => response,
      paramsSerializer: (params: any) => {
        // Because query parameters are JSON encoded, decode them to re-encode them with
        // 'qs.stringify'
        let decodedParams = JSON.parse(params);
        return qs.stringify(decodedParams);
      },
    });
  }

  /**
   * Sends an HTTP request with the `GET` method.
   */
  async get(
    endpoint: string,
    options: {
      headers?: Headers;
      params?: string;
      timeout?: number;
    } = {}
  ): Promise<HTTPResponse> {
    return await this.client.get(endpoint, {
      headers: options.headers,
      params: options.params,
      timeout: options.timeout,
    });
  }

  /**
   * Sends an HTTP request with the `POST` method.
   */
  async post(
    endpoint: string,
    options: {
      headers?: Headers;
      data?: string;
      timeout?: number;
    } = {}
  ): Promise<HTTPResponse> {
    let headers = options.headers !== undefined ? options.headers : {};
    if (options.data !== undefined) {
      setHeader(headers, Header.CONTENT_TYPE, "application/json");
    }
    return await this.client.post(endpoint, options.data, {
      headers: options.headers,
      timeout: options.timeout,
    });
  }

  /**
   * Sends an HTTP request with the `DELETE` method.
   */
  async delete(
    endpoint: string,
    options: { headers?: Headers; timeout?: number } = {}
  ): Promise<HTTPResponse> {
    return await this.client.delete(endpoint, {
      headers: options.headers,
      timeout: options.timeout,
    });
  }

  /**
   * Sends an HTTP request with the `PATCH` method.
   */
  async patch(
    endpoint: string,
    options: { headers?: Headers; data?: string; timeout?: number } = {}
  ): Promise<HTTPResponse> {
    let headers = options.headers !== undefined ? options.headers : {};
    if (options.data !== undefined) {
      setHeader(headers, Header.CONTENT_TYPE, "application/json");
    }
    return await this.client.patch(endpoint, options.data, {
      headers: options.headers,
      timeout: options.timeout,
    });
  }
}

export default HTTPClient;
export { type HTTPResponse };
