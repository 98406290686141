import { Group, Paper, Space, Text, ThemeIcon } from "@mantine/core";
import { IconChartArea } from "@tabler/icons-react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { RankingScore } from "../../api/models/games/ranking";

interface RankingScoreChartProps {
  height: string;
  width: string;
  scores: RankingScore[];
}

function RankingScoreTooltip({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<{ payload: { matchId: number; score: number } }>;
}) {
  if (active && payload && payload.length > 0) {
    return (
      <div className="ranking-score-chart__tooltip">
        <p className="ranking-score-chart__tooltip__match-id">{`Match ID : ${payload[0].payload.matchId}`}</p>
        <p className="ranking-score-chart__tooltip__score">{`Point(s) gagné(s) : ${payload[0].payload.score}`}</p>
      </div>
    );
  }
  return null;
}

function RankingScoreChart({ height, width, scores }: RankingScoreChartProps) {
  const accumulatedScores: { value: number }[] = [];
  scores.forEach((score) => {
    const previous = accumulatedScores.at(-1)?.value ?? 0;
    const value = previous + score.score;
    accumulatedScores.push({ value: value, ...score });
  });

  return (
    <div className="ranking-score-chart" style={{ width: width }}>
      <Paper shadow="xs" radius="md" p="xs">
        <Group position="apart">
          <Text color="dimmed" size="sm" weight={550}>
            Évolution du score
          </Text>
          <ThemeIcon variant="light" color="gray" size="lg" radius="md">
            <IconChartArea color="black" />
          </ThemeIcon>
        </Group>
        <Space h="1rem" />
        <div style={{ height: height }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={accumulatedScores}>
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorValue)"
                dot={false}
                strokeWidth={1}
              />
              <YAxis width={20} axisLine={false} tickLine={false} />
              <XAxis axisLine={false} tickLine={false} />
              <Tooltip content={<RankingScoreTooltip />} />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </div>
  );
}

export default RankingScoreChart;
export { type RankingScoreChartProps };
