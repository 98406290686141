import APIModel from "./base";
import UserAttrs from "../../attrs/api/models/user";
import { UserFormAttrs } from "../../attrs/api/models/user";
import { formatDate } from "../../utils/date";
import { EncodedAPIModel } from "./encoding";

enum UserRole {
  ADMIN,
  MODERATOR,
  PLAYER,
}

class UserForm implements APIModel {
  uid: string;
  name: string;
  surname: string;
  birthDate: Date;
  email: string;
  photoUrl: string | null;
  username: string;

  constructor(
    uid: string,
    name: string,
    surname: string,
    birthDate: Date,
    email: string,
    photoUrl: string | null,
    username: string
  ) {
    this.uid = uid;
    this.name = name;
    this.surname = surname;
    this.birthDate = birthDate;
    this.email = email;
    this.photoUrl = photoUrl;
    this.username = username;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[UserFormAttrs.UID] = this.uid;
    content[UserFormAttrs.NAME] = this.name;
    content[UserFormAttrs.SURNAME] = this.surname;
    content[UserFormAttrs.BIRTH_DATE] = formatDate(this.birthDate);
    content[UserFormAttrs.EMAIL] = this.email;
    content[UserFormAttrs.PHOTO_URL] = this.photoUrl;
    content[UserFormAttrs.USERNAME] = this.username;
    return JSON.stringify(content);
  }

  static decode(content: string): UserForm {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    let rawBirthDate = decodedContent[UserFormAttrs.BIRTH_DATE];
    return new UserForm(
      decodedContent[UserFormAttrs.UID],
      decodedContent[UserFormAttrs.NAME],
      decodedContent[UserFormAttrs.SURNAME],
      new Date(rawBirthDate),
      decodedContent[UserFormAttrs.EMAIL],
      decodedContent[UserFormAttrs.PHOTO_URL],
      decodedContent[UserFormAttrs.USERNAME]
    );
  }
}

class User implements APIModel {
  id: number;
  uid: string;
  name: string;
  surname: string;
  birthDate: Date;
  email: string;
  photoUrl: string | null;
  username: string;
  role: UserRole;
  banned: boolean;

  constructor(
    id: number,
    uid: string,
    name: string,
    surname: string,
    birthDate: Date,
    email: string,
    photoUrl: string | null,
    username: string,
    role: UserRole,
    banned: boolean
  ) {
    this.id = id;
    this.uid = uid;
    this.name = name;
    this.surname = surname;
    this.birthDate = birthDate;
    this.email = email;
    this.photoUrl = photoUrl;
    this.username = username;
    this.role = role;
    this.banned = banned;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[UserAttrs.ID] = this.id;
    content[UserAttrs.UID] = this.uid;
    content[UserAttrs.NAME] = this.name;
    content[UserAttrs.SURNAME] = this.surname;
    content[UserAttrs.BIRTH_DATE] = formatDate(this.birthDate);
    content[UserAttrs.EMAIL] = this.email;
    content[UserAttrs.PHOTO_URL] = this.photoUrl;
    content[UserAttrs.USERNAME] = this.username;
    content[UserAttrs.ROLE] = this.role;
    content[UserAttrs.BANNED] = this.banned;
    return JSON.stringify(content);
  }

  static decode(content: string): User {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    let rawBirthDate = decodedContent[UserAttrs.BIRTH_DATE];
    return new User(
      decodedContent[UserAttrs.ID],
      decodedContent[UserAttrs.UID],
      decodedContent[UserAttrs.NAME],
      decodedContent[UserAttrs.SURNAME],
      new Date(rawBirthDate),
      decodedContent[UserAttrs.EMAIL],
      decodedContent[UserAttrs.PHOTO_URL],
      decodedContent[UserAttrs.USERNAME],
      decodedContent[UserAttrs.ROLE],
      decodedContent[UserAttrs.BANNED]
    );
  }
}

class UserProfile implements APIModel {
  uid: string;
  username: string;
  photoUrl: string | null;

  constructor(uid: string, username: string, photoUrl: string | null) {
    this.uid = uid;
    this.username = username;
    this.photoUrl = photoUrl;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[UserAttrs.UID] = this.uid;
    content[UserAttrs.USERNAME] = this.username;
    content[UserAttrs.PHOTO_URL] = this.photoUrl;
    return JSON.stringify(content);
  }

  static decode(content: string): UserProfile {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new UserProfile(
      decodedContent[UserAttrs.UID],
      decodedContent[UserAttrs.USERNAME],
      decodedContent[UserAttrs.PHOTO_URL]
    );
  }
}

export default User;
export { UserForm, UserProfile };
