import { useState } from "react";
import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordInputProps,
  Popover,
  Progress,
} from "@mantine/core";

import { PASSWORD_REQUIREMENTS, getPasswordStrength } from "../../security";
import PasswordRequirement from "./PasswordRequirement";
import { ChangeEvent } from "react";

interface PasswordInputProps {
  passwordInputProps: MantinePasswordInputProps;
  confirmationPasswordInputProps: MantinePasswordInputProps;
}

function PasswordInput({
  passwordInputProps,
  confirmationPasswordInputProps,
}: PasswordInputProps) {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [password, setPassword] = useState("");

  const checks = Object.values(PASSWORD_REQUIREMENTS).map(
    (requirement, index) => {
      let requirementRegex = new RegExp(requirement.re);
      return (
        <PasswordRequirement
          key={index}
          label={requirement.label}
          meets={requirementRegex.test(password)}
        />
      );
    }
  );

  const onChangePasswordHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
    if (passwordInputProps.onChange) {
      passwordInputProps.onChange(event);
    }
  };

  const passwordStrength = getPasswordStrength(password);
  const progressBarColor =
    passwordStrength === 100
      ? "teal"
      : passwordStrength > 50
      ? "yellow"
      : "red";

  return (
    <div>
      <Popover
        opened={popoverOpened}
        position="bottom"
        width="target"
        transitionProps={{ transition: "pop" }}
      >
        <Popover.Target>
          <div
            onFocus={() => setPopoverOpened(true)}
            onBlur={() => setPopoverOpened(false)}
          >
            <MantinePasswordInput
              withAsterisk
              label="Mot de passe"
              placeholder="Mot de passe"
              {...{
                ...passwordInputProps,
                ...{ onChange: onChangePasswordHandler },
              }}
            />
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Progress
            color={progressBarColor}
            value={passwordStrength}
            size={5}
            mb="xs"
          />
          {checks}
        </Popover.Dropdown>
      </Popover>
      <MantinePasswordInput
        withAsterisk
        label="Confirmation du mot de passe"
        placeholder="Confirmation du mot de passe"
        {...confirmationPasswordInputProps}
      />
    </div>
  );
}

export default PasswordInput;
