import APIModel from "./base";
import RegionAttrs from "../../attrs/api/models/region";
import { EncodedAPIModel } from "./encoding";

class Region implements APIModel {
  id: number;
  name: string;
  code: string;

  constructor(id: number, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[RegionAttrs.ID] = this.id;
    content[RegionAttrs.NAME] = this.name;
    content[RegionAttrs.CODE] = this.code;
    return JSON.stringify(content);
  }

  static decode(content: string): Region {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new Region(
      decodedContent[RegionAttrs.ID],
      decodedContent[RegionAttrs.NAME],
      decodedContent[RegionAttrs.CODE]
    );
  }
}

export default Region;
