function formatDate(date: Date) {
  var month = (date.getMonth() + 1).toString();
  var day = date.getDate().toString();
  var year = date.getFullYear().toString();

  month = month.padStart(2, "0");
  day = day.padStart(2, "0");

  return [year, month, day].join("-");
}

interface HumanizeOptions {
  withTime?: boolean;
}

/**
 * Humanizes date.
 *
 * @param date input date.
 * @param options options.
 * @returns humanized date, with optional time information.
 */
function humanizeDate(date: Date, options: HumanizeOptions = {}): string {
  let dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  if (options.withTime) {
    dateTimeFormatOptions.hour = "numeric";
    dateTimeFormatOptions.minute = "numeric";
  }

  let formattedDate = date.toLocaleDateString(
    "fr-FR", //navigator.language
    dateTimeFormatOptions
  );
  formattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  return formattedDate;
}

/**
 * Gets the date of the day, with time set to midnigth.
 */
function getDateAtMidnight(): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export { formatDate, getDateAtMidnight, humanizeDate };
