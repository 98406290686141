import APIModel from "../base";
import {
  RankingAttrs,
  RankingFilterAttrs,
  RankingScoreAttrs,
} from "../../../attrs/api/models/games/ranking";
import { EncodedAPIModel } from "../encoding";

/**
 * Enumeration of ranking score statuses.
 */
enum RankingScoreStatus {
  NOT_PLAYED = "NOT_PLAYED",
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

class RankingScore implements APIModel {
  uid: string;
  matchId: number;
  score: number;
  status: RankingScoreStatus;

  constructor(
    uid: string,
    matchId: number,
    score: number,
    status: RankingScoreStatus
  ) {
    this.uid = uid;
    this.matchId = matchId;
    this.score = score;
    this.status = status;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[RankingScoreAttrs.UID] = this.uid;
    content[RankingScoreAttrs.MATCH_ID] = this.matchId;
    content[RankingScoreAttrs.SCORE] = this.score;
    content[RankingScoreAttrs.STATUS] = this.status;
    return JSON.stringify(content);
  }

  static decode(content: string): RankingScore {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new RankingScore(
      decodedContent[RankingScoreAttrs.UID],
      decodedContent[RankingScoreAttrs.MATCH_ID],
      decodedContent[RankingScoreAttrs.SCORE],
      decodedContent[RankingScoreAttrs.STATUS]
    );
  }
}

function humanizeRankingStatus(status: RankingScoreStatus) {
  switch (status) {
    case RankingScoreStatus.FAILURE:
      return "Échec";
    case RankingScoreStatus.NOT_PLAYED:
      return "Non joué";
    case RankingScoreStatus.SUCCESS:
      return "Succès";
    default:
      return "Inconnu";
  }
}

class Ranking implements APIModel {
  uid: string;
  score: number;
  position: number;
  nbPlayers: number;

  constructor(uid: string, score: number, position: number, nbPlayers: number) {
    this.uid = uid;
    this.score = score;
    this.position = position;
    this.nbPlayers = nbPlayers;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[RankingAttrs.UID] = this.uid;
    content[RankingAttrs.SCORE] = this.score;
    content[RankingAttrs.POSITION] = this.position;
    content[RankingAttrs.NB_PLAYERS] = this.nbPlayers;
    return JSON.stringify(content);
  }

  static decode(content: string): Ranking {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new Ranking(
      decodedContent[RankingAttrs.UID],
      decodedContent[RankingAttrs.SCORE],
      decodedContent[RankingAttrs.POSITION],
      decodedContent[RankingAttrs.NB_PLAYERS]
    );
  }
}

class RankingFilter implements APIModel {
  limit: number;
  offset: number;
  leagueIds: number[] | null;
  seasonIds: number[] | null;

  constructor(
    limit: number,
    offset: number,
    leagueIds: number[] | null = null,
    seasonIds: number[] | null = null
  ) {
    this.limit = limit;
    this.offset = offset;
    this.leagueIds = leagueIds;
    this.seasonIds = seasonIds;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[RankingFilterAttrs.LIMIT] = this.limit;
    content[RankingFilterAttrs.OFFSET] = this.offset;
    if (this.leagueIds !== null && this.leagueIds.length > 0) {
      content[RankingFilterAttrs.LEAGUE_IDS] = this.leagueIds.join(",");
    }
    if (this.seasonIds !== null && this.seasonIds?.length > 0) {
      content[RankingFilterAttrs.SEASON_IDS] = this.seasonIds.join(",");
    }
    return JSON.stringify(content);
  }

  static decode(content: string): RankingFilter {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new RankingFilter(
      decodedContent[RankingFilterAttrs.LIMIT],
      decodedContent[RankingFilterAttrs.OFFSET],
      decodedContent[RankingFilterAttrs.LEAGUE_IDS],
      decodedContent[RankingFilterAttrs.SEASON_IDS]
    );
  }
}

export {
  Ranking,
  RankingFilter,
  RankingScore,
  RankingScoreStatus,
  humanizeRankingStatus,
};
