import { Select } from "@mantine/core";

import League from "../../api/models/league";
import { useState } from "react";

interface LeagueSelectProps {
  leagues: string[];
  defaultLeague?: string;
  onChange(leagues: string | null): void;
}

function LeagueSelect({ leagues, defaultLeague, onChange }: LeagueSelectProps) {
  const [selectedLeague, setSelectedLeague] = useState<string | null>(
    defaultLeague ?? null
  );

  const onChangeHandler = (selectedLeagueName: string | null) => {
    setSelectedLeague(selectedLeagueName);
    if (selectedLeagueName === null) {
      return onChange(null);
    }
    const selectedLeague: string | undefined = leagues.find(
      (league) => selectedLeagueName === league
    );
    onChange(selectedLeague!);
  };

  return (
    <div className="league-select">
      <Select
        searchable
        data={leagues}
        placeholder="Sélectionner une ligue"
        value={selectedLeague}
        onChange={onChangeHandler}
        nothingFound="Aucune ligue trouvée"
        radius="md"
        hoverOnSearchChange
      />
    </div>
  );
}

export default LeagueSelect;
export { type LeagueSelectProps };
