const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAg-_6gz21hD47SNdwU3qbPTNh6rgcnTHk",
  authDomain: "soccer-pro-fr-293367.firebaseapp.com",
  projectId: "soccer-pro-fr-293367",
  storageBucket: "soccer-pro-fr-293367.appspot.com",
  messagingSenderId: "450632517444",
  appId: "1:450632517444:web:a881350bc7a053352cf911",
  measurementId: "G-J5HGBCYTCK",
};

const API_BASE_URL = "https://api.soccerpro.fr/api/v0";

export { API_BASE_URL, FIREBASE_CONFIG };
