import AccountService from "../../../services/account";

interface SettingsPageProps {
  accountService: AccountService;
}

function SettingsPage({ accountService }: SettingsPageProps) {
  return <div className="settings-page"></div>;
}

export default SettingsPage;
export { type SettingsPageProps };
