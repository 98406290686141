import APIModel from "./base";
import LeagueAttrs from "../../attrs/api/models/league";
import { EncodedAPIModel } from "./encoding";
import Region from "./region";

class League implements APIModel {
  id: number;
  name: string;
  code: string | null;
  region: Region;
  crest: string;

  constructor(
    id: number,
    name: string,
    code: string,
    region: Region,
    crest: string
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.region = region;
    this.crest = crest;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[LeagueAttrs.ID] = this.id;
    content[LeagueAttrs.NAME] = this.name;
    content[LeagueAttrs.CODE] = this.code;
    content[LeagueAttrs.REGION] = this.region;
    content[LeagueAttrs.CREST] = this.crest;
    return JSON.stringify(content);
  }

  static decode(content: string): League {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new League(
      decodedContent[LeagueAttrs.ID],
      decodedContent[LeagueAttrs.NAME],
      decodedContent[LeagueAttrs.CODE],
      Region.decode(JSON.stringify(decodedContent[LeagueAttrs.REGION])),
      decodedContent[LeagueAttrs.CREST]
    );
  }
}

export default League;
