import APIModel from "./base";
import TeamAttrs from "../../attrs/api/models/team";
import { EncodedAPIModel } from "./encoding";

class Team implements APIModel {
  id: number;
  name: string;
  leagueId: number;
  crest: string;

  constructor(id: number, name: string, leagueId: number, crest: string) {
    this.id = id;
    this.name = name;
    this.leagueId = leagueId;
    this.crest = crest;
  }

  encode(): string {
    let content: EncodedAPIModel = {};
    content[TeamAttrs.ID] = this.id;
    content[TeamAttrs.NAME] = this.name;
    content[TeamAttrs.LEAGUE_ID] = this.leagueId;
    content[TeamAttrs.CREST] = this.crest;
    return JSON.stringify(content);
  }

  static decode(content: string): Team {
    let decodedContent = JSON.parse(content) as EncodedAPIModel;
    return new Team(
      decodedContent[TeamAttrs.ID],
      decodedContent[TeamAttrs.NAME],
      decodedContent[TeamAttrs.LEAGUE_ID],
      decodedContent[TeamAttrs.CREST]
    );
  }
}

export default Team;
