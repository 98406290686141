import { useEffect, useState } from "react";
import { Grid, Center, Title, Space, Loader, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

import AccountService from "../../../services/account";
import { APIService } from "../../../services/api";
import Match, { MatchFilter } from "../../../api/models/match";
import MatchesTable from "../MatchesTable";
import BetManagement from "../BetManagement";
import MatchesFilters from "../MatchesFilters";
import { getDateAtMidnight } from "../../../utils/date";

interface MatchesPageProps {
  accountService: AccountService;
  apiService: APIService;
}

const MatchesPage = ({ accountService, apiService }: MatchesPageProps) => {
  const [selectedLeagueId, setSelectedLeagueId] = useState<number>(1);
  const [startAfter, setStartAfter] = useState<Date | null>(
    getDateAtMidnight()
  );
  const [activePage, setActivePage] = useState(1);

  const { data: leagues } = useQuery({
    queryKey: ["leagues"],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchLeagues({ idToken: idToken });
    },
    placeholderData: [],
  });

  const matchesAmount: number = 17;
  const matchesFilter = new MatchFilter(
    matchesAmount,
    (activePage - 1) * matchesAmount,
    selectedLeagueId,
    null,
    null,
    startAfter
  );
  const { data: matches, isLoading: matchesQueryIsLoading } = useQuery({
    queryKey: ["matches", matchesFilter.encode()],
    queryFn: async () => {
      const idToken = await accountService.generateIDToken();
      return await apiService.fetchMatches(matchesFilter, { idToken: idToken });
    },
    placeholderData: [],
  });

  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);

  useEffect(() => {
    setSelectedMatch(null);
  }, [selectedLeagueId, startAfter, activePage]);

  return (
    <div className="matches-page">
      <Title>Matchs</Title>
      <Text color="dimmed" size="md" weight={500}>
        Matchs en temps réel
      </Text>
      <Space h="2rem" />
      <Grid grow columns={12}>
        <Grid.Col span={2}>
          <MatchesFilters
            leagues={leagues!}
            defaultLeagueId={selectedLeagueId}
            onLeagueIdChange={setSelectedLeagueId}
            onStartAfterChange={setStartAfter}
          />
        </Grid.Col>
        <Grid.Col span={7}>
          {matchesQueryIsLoading ? (
            <Center>
              <Loader variant="dots" />
            </Center>
          ) : (
            <Center>
              <MatchesTable
                matches={matches!}
                onMatchSelect={setSelectedMatch}
                defaultPage={activePage}
                onPageChange={setActivePage}
              />
            </Center>
          )}
        </Grid.Col>
        <Grid.Col span={3}>
          <BetManagement
            accountService={accountService}
            apiService={apiService}
            match={selectedMatch}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default MatchesPage;
