import { Grid } from "@mantine/core";
import RankingScoreComponent from "./RankingScore";
import RankingPosition from "./RankingPosition";
import RankingProportions from "./RankingProportions";
import RankingScoreChart from "./RankingScoreChart";
import { Ranking, RankingScore } from "../../api/models/games/ranking";
import RankingSuccess from "./RankingSuccess";

interface RankingPerformanceProps {
  ranking: Ranking;
  scores: RankingScore[];
}

function RankingPerformance({ ranking, scores }: RankingPerformanceProps) {
  return (
    <div className="ranking-performance" style={{ height: "17rem" }}>
      <Grid columns={5}>
        <Grid.Col span={2}>
          <Grid columns={2} justify="space-around" grow>
            <Grid.Col span={1}>
              <RankingScoreComponent score={ranking.score} />
            </Grid.Col>
            <Grid.Col span={1}>
              <RankingSuccess scores={scores} />
            </Grid.Col>
            <Grid.Col span={1}>
              <RankingPosition
                size={75}
                thickness={3}
                position={ranking.position}
                nbPlayers={ranking.nbPlayers}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <RankingProportions size={75} thickness={3} scores={scores} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={3}>
          <RankingScoreChart height="12rem" width="100%" scores={scores} />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default RankingPerformance;
export { type RankingPerformanceProps };
