import {
  Anchor,
  Button,
  Checkbox,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { isEmail, useForm, isNotEmpty } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

import AccountService, { Account } from "../../../services/account";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

const AUTHENTICATION_NOTIFICATION_ID: string = "authentication";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: rem(900),
    backgroundSize: "cover",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1517747614396-d21a78b850e8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb)",
  },

  form: {
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: rem(900),
    maxWidth: rem(450),
    paddingTop: rem(80),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

interface AuthenticationFormFields {
  email: string;
  password: string;
  keepLoggedIn: boolean;
}

/**
 * Initializes each authentication form field to its default value.
 *
 * @returns a default value for each field.
 */
function initializeFields(): AuthenticationFormFields {
  return {
    email: "",
    password: "",
    keepLoggedIn: false,
  };
}

function validateFields(fields: AuthenticationFormFields) {
  return {
    email: isEmail("Email invalide")(fields.email),
    password: isNotEmpty("Un mot de passe doit être renseigné")(
      fields.password
    ),
  };
}

interface AuthenticationPageProps {
  accountService: AccountService;
  onSuccess(account: Account): void;
  onRegister(): void;
}

function AuthenticationPage({
  accountService,
  onSuccess,
  onRegister,
}: AuthenticationPageProps) {
  const { classes } = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<AuthenticationFormFields>({
    initialValues: initializeFields(),
    validate: (values) => validateFields(values),
    validateInputOnBlur: true,
  });

  const authenticationMutation = useMutation({
    mutationFn: async (fields: AuthenticationFormFields): Promise<Account> => {
      const account = await accountService.signIn(
        fields.email,
        fields.password
      );
      await accountService.setPersistence(fields.keepLoggedIn);
      return account;
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: async (account: Account) => {
      notifications.show({
        id: AUTHENTICATION_NOTIFICATION_ID,
        color: "green",
        message: "Authentification réussie",
        icon: <IconCircleCheck size="1rem" />,
        autoClose: 3000,
      });
      onSuccess(account);
    },
    onError: (error: Error) => {
      notifications.show({
        id: AUTHENTICATION_NOTIFICATION_ID,
        color: "red",
        title: "Une erreur est survenue lors de l'authentification'",
        message: error.toString(),
        icon: <IconCircleX size="1rem" />,
        autoClose: 5000,
      });
      setIsLoading(false);
    },
  });

  return (
    <div className="authentication-page">
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <Title
            order={1}
            className={classes.title}
            ta="center"
            mt="md"
            mb={50}
          >
            Bienvenue sur SoccerPro !
          </Title>

          <form
            onSubmit={form.onSubmit((fields) =>
              authenticationMutation.mutate(fields)
            )}
          >
            <TextInput
              label="Adresse mail"
              placeholder="votre@mail.com"
              size="md"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Mot de passe"
              placeholder="Mot de passe"
              mt="md"
              size="md"
              {...form.getInputProps("password")}
            />
            <Checkbox
              label="Rester connecté"
              mt="xl"
              size="md"
              {...form.getInputProps("keepLoggedIn", { type: "checkbox" })}
            />
            <Button
              type="submit"
              loading={isLoading}
              fullWidth
              mt="xl"
              size="md"
            >
              Se connecter
            </Button>
          </form>

          <Text ta="center" mt="md">
            Pas de compte ?{" "}
            <Anchor<"a"> href="#" weight={700} onClick={onRegister}>
              S'enregistrer
            </Anchor>
          </Text>
        </Paper>
      </div>
    </div>
  );
}

export default AuthenticationPage;
export { type AuthenticationFormFields };
