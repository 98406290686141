import { initializeApp as initializeFirebaseApp } from "firebase/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AccountService from "./services/account";
import { API_BASE_URL, FIREBASE_CONFIG } from "./config";
import { APIService } from "./services/api";
import HTTPClient from "./io/http/client";
import Router from "./Router";

function App() {
  // Initializes a new query client that will be used in components to handle queries (retry,
  // refetch, error and success management, etc.)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Each query is considered stale after 1 minute
        staleTime: 60_000,
        // Previous data is kept when fetching based on a new query key (i.e. resource ID changed)
        keepPreviousData: true,
      },
    },
  });

  const apiService = new APIService(new HTTPClient(API_BASE_URL));
  const accountService = new AccountService(
    initializeFirebaseApp(FIREBASE_CONFIG),
    apiService
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router accountService={accountService} apiService={apiService} />
    </QueryClientProvider>
  );
}

export default App;
