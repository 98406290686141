import { useEffect, useState } from "react";
import { OddCategory, getOddCategoryName } from "../../api/models/bet";
import { SegmentedControl } from "@mantine/core";

interface BetCategorySelectProps {
  defaultCategory: OddCategory;
  onChange(category: OddCategory): void;
  // Only the categories specified in this array are enabled
  only?: OddCategory[];
}

function BetCategorySelect({
  defaultCategory,
  onChange,
  only,
}: BetCategorySelectProps) {
  const [value, setValue] = useState(defaultCategory.toString());

  const generateDataElement = (category: OddCategory) => {
    let isDisabled = false;
    if (only !== undefined) {
      isDisabled = only.includes(category) ? false : true;
    }
    return {
      label: getOddCategoryName(category),
      value: category.toString(),
      disabled: isDisabled,
    };
  };

  const onChangeHandler = (category: string) => {
    setValue(category);
    onChange(parseInt(category) as OddCategory);
  };

  useEffect(() => {
    onChangeHandler(defaultCategory.toString());
  }, [defaultCategory]);

  return (
    <div className="bet-category-select">
      <SegmentedControl
        fullWidth
        value={value}
        onChange={onChangeHandler}
        data={[
          generateDataElement(OddCategory.ODD_C1),
          generateDataElement(OddCategory.ODD_CN),
          generateDataElement(OddCategory.ODD_C2),
          generateDataElement(OddCategory.ODD_1N),
          generateDataElement(OddCategory.ODD_N2),
          generateDataElement(OddCategory.ODD_12),
        ]}
        radius="xl"
        color="blue"
      />
    </div>
  );
}

export default BetCategorySelect;
export { type BetCategorySelectProps };
