class BetFormAttrs {
  static readonly MATCH_ID: string = "matchId";
  static readonly ODD_CATEGORY: string = "oddCategory";
  static readonly HOME_SCORE: string = "homeScore";
  static readonly AWAY_SCORE: string = "awayScore";
}

class BetAttrs {
  static readonly ID: string = "id";
  static readonly MATCH_ID: string = "matchId";
  static readonly USER_ID: string = "userId";
  static readonly ODD_CATEGORY: string = "oddCategory";
  static readonly HOME_SCORE: string = "homeScore";
  static readonly AWAY_SCORE: string = "awayScore";
}

class BetFilterAttrs {
  static readonly MATCH_IDS: string = "matchIds";
  static readonly NB_BETS: string = "nbBets";
  static readonly OFFSET: string = "offset";
  static readonly SEASON_ID: string = "seasonId";
  static readonly LEAGUE_ID: string = "leagueId";
  static readonly OVER: string = "over";
}

class BetResultAttrs {
  static readonly ID: string = "id";
  static readonly BET_ID: string = "betId";
  static readonly IS_ODD_SUCCESS: string = "isOddSuccess";
  static readonly IS_SCORE_SUCCESS: string = "isScoreSuccess";
}

export { BetAttrs, BetFilterAttrs, BetFormAttrs, BetResultAttrs };
