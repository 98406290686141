import { useEffect, useState } from "react";
import { Center, Grid, Loader, Space, Text, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

import AccountService from "../../../services/account";
import { APIService } from "../../../services/api";
import Season, { SeasonFilter } from "../../../api/models/season";
import { RankingFilter } from "../../../api/models/games/ranking";
import LeagueSelect from "../LeagueSelect";
import League from "../../../api/models/league";
import SeasonSelect from "../SeasonSelect";
import RankingScoreTable from "../RankingScoreTable";
import RankingPerformance from "../RankingPerformance";

interface StatisticsPageProps {
  accountService: AccountService;
  apiService: APIService;
}

const FETCH_LEAGUES_QUERY_ID: string = "leagues";
const FETCH_SEASONS_QUERY_ID: string = "seasons";
const FETCH_RANKING_SCORES_QUERY_ID: string = "ranking-scores";
const FETCH_RANKING_QUERY_ID: string = "ranking";

const DEFAULT_RANKING_TABLE_SIZE: number = 20;

function StatisticsPage({ accountService, apiService }: StatisticsPageProps) {
  const [selectedLeague, setSelectedLeague] = useState<League | null>(null);
  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);

  const { data: leagues } = useQuery({
    queryKey: [FETCH_LEAGUES_QUERY_ID],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchLeagues({ idToken: idToken });
    },
    placeholderData: [],
  });

  const seasonQueryEnabled = selectedLeague !== null;
  const seasonFilter = new SeasonFilter(
    10,
    0,
    seasonQueryEnabled ? [selectedLeague.id] : null
  );
  const { data: seasons } = useQuery({
    queryKey: [FETCH_SEASONS_QUERY_ID, seasonFilter.encode()],
    queryFn: async () => {
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchSeasons(seasonFilter, { idToken: idToken });
    },
    placeholderData: [],
    enabled: seasonQueryEnabled,
  });

  useEffect(() => {
    if (selectedLeague === null) {
      setSelectedLeague(leagues!.at(0) ?? null);
    }
  }, [leagues]);

  useEffect(() => {
    if (selectedSeason === null) {
      setSelectedSeason(seasons!.at(0) ?? null);
    }
  }, [seasons]);

  const leagueChangeHandler = (leagueName: string) => {
    const selectedLeague: League | undefined = leagues!.find(
      (league) => leagueName === league.name
    );
    setSelectedLeague(selectedLeague ?? null);
  };

  const rankingQueryEnabled =
    selectedLeague !== null && selectedSeason !== null;
  // Currently, ranking table size cannot be modified by the user
  const [rankingTableSize] = useState<number>(DEFAULT_RANKING_TABLE_SIZE);
  const [rankingTablePage, setRankingTablePage] = useState<number>(1);
  const rankingFilter = new RankingFilter(
    rankingTableSize,
    // Pagination number starts at 1, substract 1 to the page number to correctly slice the array of
    // opponent rankings
    (rankingTablePage - 1) * rankingTableSize,
    selectedLeague !== null ? [selectedLeague.id] : null,
    selectedSeason !== null ? [selectedSeason.id] : null
  );

  const {
    data: rankingScores,
    isLoading: rankingScoresQueryIsLoading,
    isError: rankingScoresQueryIsError,
  } = useQuery({
    queryKey: [FETCH_RANKING_SCORES_QUERY_ID, rankingFilter.encode()],
    queryFn: async () => {
      let account = await accountService.getCurrentAccount();
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchRankingScores(account.uid, rankingFilter, {
        idToken: idToken,
      });
    },
    placeholderData: [],
    enabled: rankingQueryEnabled,
    retry: false,
  });

  const {
    data: ranking,
    isLoading: rankingQueryIsLoading,
    isError: rankingQueryIsError,
  } = useQuery({
    queryKey: [FETCH_RANKING_QUERY_ID, rankingFilter.encode()],
    queryFn: async () => {
      let account = await accountService.getCurrentAccount();
      let idToken = await accountService.generateIDToken();
      return await apiService.fetchRanking(account.uid, rankingFilter, {
        idToken: idToken,
      });
    },
    placeholderData: null,
    enabled: rankingQueryEnabled,
    retry: false,
  });

  return (
    <div className="statistics-page">
      <Grid>
        <Grid.Col span={8}>
          <Title>Statistiques</Title>
          <Text color="dimmed" size="md" weight={500}>
            Vos statistiques personnelles, en temps réel
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <LeagueSelect
            leagues={leagues!.map((l) => l.name)}
            defaultLeague={selectedLeague?.name}
            onChange={leagueChangeHandler}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <SeasonSelect
            seasons={seasons!}
            onChange={setSelectedSeason}
            disabled={seasons!.length === 0}
          />
        </Grid.Col>
      </Grid>
      <Space h="2rem" />
      {rankingQueryIsLoading || rankingScoresQueryIsLoading ? (
        <Center>
          <Loader variant="dots" />
        </Center>
      ) : ranking === null ||
        rankingScores?.length === 0 ||
        rankingQueryIsError ||
        rankingScoresQueryIsError ? (
        <Text>Aucune donnée à afficher.</Text>
      ) : (
        <div>
          <Title order={2}>{selectedLeague?.name}</Title>
          <Space h="1rem" />
          <RankingPerformance ranking={ranking!} scores={rankingScores!} />
          <Space h="1rem" />
          <RankingScoreTable
            accountService={accountService}
            apiService={apiService}
            scores={rankingScores!}
            onPageChange={setRankingTablePage}
            defaultPage={rankingTablePage}
          />
        </div>
      )}
    </div>
  );
}

export default StatisticsPage;
export { type StatisticsPageProps };
