import {
  Card,
  Center,
  Image,
  Loader,
  Space,
  Table,
  Text,
  Title,
  createStyles,
} from "@mantine/core";

import { humanizeDate } from "../../utils/date";
import InfinitePagination from "../UI/InfinitePagination";
import Match from "../../api/models/match";
import MatchStatus from "./MatchStatus";
import "./MatchesTable.css";

function highlightRow(event: React.MouseEvent<HTMLTableRowElement>): void {
  const clickedRow = event.currentTarget;

  // Remove the "highlighted-row" class from all rows
  const allRows = document.querySelectorAll("tr");
  allRows.forEach((row) => row.classList.remove("highlighted-row"));

  // Add the "highlighted-row" class to the clicked row
  clickedRow.classList.add("highlighted-row");
}

const useRowStyles = createStyles((theme) => ({
  tr: {
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
      cursor: "pointer",
    },
  },
}));

interface MatchRowProps {
  match: Match;
  onSelect(match: Match): void;
}

function MatchRow({ match, onSelect }: MatchRowProps) {
  const homeTeam = match.homeTeam;
  const homeTeamScore = match.result?.homeScore.toString() ?? "-";
  const awayTeam = match.awayTeam;
  const awayTeamScore = match.result?.awayScore.toString() ?? "-";

  let formattedStartAt = humanizeDate(match.status.startAt, { withTime: true });

  const { classes } = useRowStyles();

  return (
    <tr
      key={match.id}
      className={classes.tr}
      onClick={(event) => {
        onSelect(match);
        highlightRow(event);
      }}
    >
      <td style={{ textAlign: "center" }}>
        <Text>{formattedStartAt}</Text>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text>{match.day}</Text>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Text fw={600}>{homeTeam.name}</Text>
          <Image withPlaceholder maw="1rem" src={homeTeam.crest} />
        </div>
      </td>
      <td style={{ textAlign: "center" }}>
        <Text fw={600}>{`${homeTeamScore} : ${awayTeamScore}`}</Text>
      </td>
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Image
            withPlaceholder
            height="1rem"
            width="1rem"
            maw="1rem"
            src={awayTeam.crest}
          />
          <Text fw={600}>{awayTeam.name}</Text>
        </div>
      </td>
      <td style={{ textAlign: "center" }}>
        <MatchStatus status={match.status} />
      </td>
    </tr>
  );
}

function MatchesHeader() {
  return (
    <thead className="matches-table__header" style={{ background: "#fbfbfd" }}>
      <tr>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Date
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Journée
          </Text>
        </th>
        <th style={{ textAlign: "right" }}>
          <Text color="dimmed" size="md" weight={500}>
            Domicile
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Score
          </Text>
        </th>
        <th style={{ textAlign: "left" }}>
          <Text color="dimmed" size="md" weight={500}>
            Extérieur
          </Text>
        </th>
        <th style={{ textAlign: "center" }}>
          <Text color="dimmed" size="md" weight={500}>
            Statut
          </Text>
        </th>
      </tr>
    </thead>
  );
}

interface MatchesTableProps {
  matches: Match[];
  onMatchSelect(match: Match): void;
  defaultPage?: number;
  onPageChange(page: number): void;
}

const MatchesTable = ({
  matches,
  onMatchSelect,
  defaultPage = 1,
  onPageChange,
}: MatchesTableProps) => {
  const rows: Array<React.ReactElement<MatchesTableProps>> = [];
  matches.forEach((match) => {
    rows.push(
      <MatchRow key={match.id} match={match} onSelect={onMatchSelect} />
    );
  });

  return (
    <div className="matches-table" style={{ width: "100%" }}>
      <Title order={4}>Matchs</Title>
      <Space h="1rem" />
      <Card shadow="xs" radius="lg" py="0" withBorder>
        {rows.length === 0 ? (
          <Center>
            <Loader variant="dots" />
          </Center>
        ) : (
          <>
            <Card.Section>
              <Table>
                <MatchesHeader />
                <tbody>{rows}</tbody>
              </Table>
            </Card.Section>
          </>
        )}
      </Card>
      <Space h="0.5rem" />
      <Center>
        <InfinitePagination
          onPageChange={onPageChange}
          defaultPage={defaultPage}
        />
      </Center>
    </div>
  );
};

export default MatchesTable;
