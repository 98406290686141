import { Paper, Text } from "@mantine/core";

import {
  RankingScore,
  RankingScoreStatus,
} from "../../api/models/games/ranking";

interface RankingSuccessProps {
  scores: RankingScore[];
}

function RankingSuccess({ scores }: RankingSuccessProps) {
  let nbSuccessScores = scores.filter(
    (score) => score.status === RankingScoreStatus.SUCCESS
  ).length;

  let nbScores = scores.length;

  let percentSuccesses = (nbSuccessScores * 100) / nbScores;

  return (
    <div className="ranking-success">
      <Paper shadow="xs" radius="md" p="xs">
        <Text color="dimmed" size="sm" weight={550}>
          Taux de succès
        </Text>
        <Text weight={700} size="xl">
          {`${percentSuccesses.toFixed(1)}%`}
        </Text>
      </Paper>
    </div>
  );
}

export default RankingSuccess;
export { type RankingSuccessProps };
